@import 'all.scss';
@import 'sprite.scss';
@import 'header.scss';
@import 'footer.scss';
@import 'mobile-menu.scss';
@import 'remodal-default-theme.scss';
@import 'remodal.scss';
@import 'product-slider.scss';
@import 'storage-info-popup.scss';

* {
    box-sizing: border-box;

    &:before,
    &:after {
        box-sizing: border-box;
    }

    p {
        &:last-of-type {
            margin-bottom: 0px;
        }
    }
}

#wrapper {
    overflow: hidden;
}

body {
    padding: 0px;
    margin: 0px;
    display: block;
    color: $primary-color;
    font-weight: 400;
    font-size: $primary-fsize;
    line-height: $primary-lheight;
    font-family: $primary-font-family;

    -webkit-overflow-scrolling: touch;
    text-rendering: auto;

    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    overflow-y: auto;
    overflow-x: hidden;

    &.noscroll {
        overflow-y:hidden;
    }
}

img {
    display: block;
    margin: 0px;
    max-width: 100%;
}

.lazy:not(.loaded) {
    opacity:0;
}

.content-width {
    width: 100%;
    max-width: $content-width;
    padding: 0px 30px 0px 30px;
    margin: auto;

    &.thin-content {
        max-width: $thin-content;
    }

    &.wide-content {
        max-width: $wide-content;
    }
}

a {
    color:$red;
    transition:$color-transition;
}

.hidden-mail-link {
    .hiddenmail {
        &:before, &:after {
            font-family:$primary-font-family;
            color:currentColor;
        }

        &:before {
            content:attr(data-user);
        }

        &:after {
            content:attr(data-domain);
        }
    }
}

.arrow-link {
    
    text-decoration: none;
    font-size:0px;
    line-height:0px;

    .text {
        font-weight:$bold-fweight;
        font-size:12px;
        line-height:16px;
        letter-spacing:0.2px;
    }

    .icon-outer {
        display:inline;
        transition: margin $transition-duration $transition-timing;
        margin-left:7px;

        .icon {
            display:inline;
        }
    }
}

p {
    margin:0px 0px 10px 0px;
}

h1, .like-h1,
h2, .like-h2,
h3, .like-h3,
h4, .like-h4 {
    font-family:$primary-font-family;
    font-weight: 400;
    margin:0px;
    padding:0px;
}

h2, .like-h2 {
    font-size:26px;
    line-height:34px;
    font-weight:$bold-fweight;
}

h1, .like-h1 {
    font-size:34px;
    line-height:42px;
    font-weight:$bold-fweight;
}

h3, .like-h3 {
    font-size:20px;
    line-height:28px;
}

h4, .like-h4 {
    font-size:18px;
    line-height:26px;
}

.hyphens {
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.field {
    appearance: none;
    outline:none;
    box-shadow:none;
    border:1px solid $field-border-color;
    color:$primary-color;
    font-family: $primary-font-family;
    font-size:14px;
    line-height:18px;
    padding:0px 22px 0px 14px;
    height:46px;
    border-radius:$border-radius;
    background-color:$white;
    transition: $bg-color-transition;

    &.has-error, &:invalid {
        background:url('../img/svg/field-error.svg') no-repeat $light-red right 9px top 18px / 8px 8px;
    }

    &.valid {
        background:url('../img/svg/green-check.svg') no-repeat right 9px top 18px / 9px 8px;
    }
}

@include placeholder {
    color:$primary-color;
    opacity:0.8;
}

.button {
    display:inline-flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    box-shadow:none;
    outline:none;
    font-family:$primary-font-family;
    font-size:0px;
    line-height:0px;
    letter-spacing:0px;
    height:46px;
    padding:0px 20px 0px 20px;
    background-color:$primary-button-background;
    color:$white;
    font-weight:$bold-fweight;
    cursor: pointer;
    transition:$bg-color-transition, $color-transition;
    border:none;
    text-transform:uppercase;
    border-radius:$border-radius;
    text-decoration:none;

    .text {
        font-size:14px;
        line-height:14px;
        letter-spacing:1px;
    }

    &.cart-button {
        .icon {
            width:23px;
            height:19px;
        }
    }

    &.empty-button {
        background:$white;
        color:$primary-button-background;
        border:1px solid $primary-button-background;
        padding:0px 19px 0px 19px;
    }


    &.with-icon {
        .text {
            margin-left:6px;
        }
    }
}

.product-tag {
    background:$primary-color;
    border-radius:$border-radius;
    color:$white;
    display:inline-flex;
    justify-content:center;
    align-items:center;
    font-size:12px;
    line-height:12px;
    font-weight:$bold-fweight;
    padding:0px 5px 0px 5px;
    height:22px;
    letter-spacing:0.1px;
}

.warranty-tag {
    background:$red;
    color:$white;
    display:inline-flex;
    justify-content:center;
    align-items:center;
    height:24px;
    font-size:12px;
    line-height:12px;
    font-weight:$bold-fweight;
    letter-spacing:0.1px;
    padding: 0px 5px 0px 5px;
    position:relative;

    &:after {
        content:'';
        position:absolute;
        left:100%;
        top:0px;
        bottom:0px;
        border-style:solid;
        border-width:12px 0px 12px 6px;
        border-color: transparent transparent transparent $red;
    }
}

.shipping-tag {
    background:$white;
    color:$red;
    display:inline-flex;
    justify-content:center;
    align-items:center;
    font-size:12px;
    line-height:12px;
    font-weight:$bold-fweight;
    padding:0px 5px 0px 5px;
    height:22px;
    letter-spacing:0.1px;

    .text {
        margin-left:5px;
    }
}

.trust-section {
    background:$light-gray;
    padding:15px 0px 12px 0px;

    .content-width, > .inner-content {
        display:flex;
        justify-content:center;
        align-items:center;
        flex-wrap:wrap;
    }

    .trust-item {
        display:none;
        font-size:0px;
        line-height:0px;
        text-decoration:none;
        color:$primary-color;
        margin:0px 22px 0px 22px;

        .text {
            margin-left:6px;
            font-size:12px;
            line-height:17px;
            white-space: nowrap;
        }

        .orszag-boltja-icon {
            color:$blue;
            width:35px;
            height:21px;
        }

        .evhonlapja-icon {
            color:$blue;
            width:16px;
            height:27px;
        }

        &:not(.visible-from-mobile) {
            display:inline-flex;
            align-items:center;
        }
    }
}

.visible-from-mobile, .visible-from-tablet {
    display:none;
}

.overflow-hidden {
    overflow:hidden;
}

.breadcrumb {
    display:flex;
    font-size:12px;
    line-height:14px;
    margin:10px 0px 35px 0px;

    ul {
        list-style:none;
        margin:0px;
        padding:0px;
        display:flex;
        flex-wrap:wrap;
        text-transform:lowercase;

        li {
            display:flex;
            &:after {
                content:'>';
                display:block;
                margin:0px 10px 0px 10px;
                font-size:12px;
                line-height:14px;
                color:$primary-color;
                font-family:$primary-font-family;
            }

            &:last-of-type {
                &:after {
                    display:none;
                }
            }
        }
    }

    a {
        text-decoration:none;

        .mobile-label {
            display:none;
        }
    }
}

main {
    &.without-breadcrumb {
        padding-top:60px;
    }

    &.has-bottom-padding {
        padding-bottom:80px;
    }
}

select.custom-select {
    font-family:$primary-font-family;
    height:100%;
    position:relative;
    z-index:3;
    font-size:14px;
    line-height:22px;
    background:url('../img/svg/blue-arrow-down.svg') no-repeat $white right 9px top 18px / 12px 7px;
    border:1px solid $field-border-color;
    border-radius:$border-radius;
    appearance: none;
    box-shadow:none;
    outline:0;
    padding:0px 22px 0px 14px;
    height:46px;
    color:#95999F;

    &.has-error {
        background:url('../img/svg/red-arrow-down.svg') no-repeat $light-red right 9px top 18px / 12px 7px;
    }

    &.has-value {
        color:$primary-color;
    }

    option {
        color:$primary-color;
        background:$white;
    }
}

@-moz-document url-prefix() {
    select.custom-select {
        text-indent: -2px;
    }
  }
  

.thin-custom-select {
    position:relative;
    display:block;
    text-align:right;

    &:after {
        content:'';
        background:url('../img/svg/arrow-down.svg') no-repeat center center / auto;
        display:block;
        height:6px;
        width:9px;
        position:absolute;
        top:0px;
        right:0px;
        bottom:0px;
        margin:auto;
    }

    select {
        font-family:$primary-font-family;
        font-size:14px;
        line-height:18px;
        color:$primary-color;
        text-decoration:none;
        box-shadow:none;
        outline:none;
        border:none;
        background:transparent;
        z-index:4;
        display:block;
        position:relative;
        width:100%;
        height:100%;
        appearance:none;
        text-align:right;
        padding:0px 16px 0px 0px;

        option {
            text-align:right;
        }
    }
}

input[type="checkbox"] {
    display:none;
    width:0px;
    height:0px;

    & + label {
        position:relative;
        display:inline-block;
        padding-left:21px;
        font-size:12px;
        line-height:16px;
        letter-spacing:0.2px;
        cursor:pointer;

        &:before {
            content:'';
            position:absolute;
            width:16px;
            height:16px;
            border-radius:3px;
            border:1px solid $secondary-color;
            background:$white;
            display:block;
            top:0px;
            left:0px;
            transition:$border-color-transition, $bg-color-transition;
        }

        &:after {
            content:'';
            display:block;
            position:absolute;
            width:7px;
            height:7px;
            background:url('../img/svg/check.svg') no-repeat center center / cover;
            top:5px;
            left:5px;
        }
    }

    &:checked + label {
        &:before {
            border-color:$red;
            background:$red;
        }
    }
}

input[type="radio"] {
    display:none;
    width:0px;
    height:0px;

    &:not(.method-selector) {
        & + label {
            display:inline-block;
            position:relative;
            padding-left:25px;
            font-size:14px;
            line-height:18px;

            &:before, &:after {
                content:'';
                display:block;
                position:absolute;
                font-size:0px;
                line-height:0px;
                border-radius:50%;
                transition:$opacity-transition, $border-color-transition;
            }

            &:before {
                width:16px;
                height:16px;
                border:1px solid $primary-color;
                top:1px;
                left:0px;
            }

            &:after {
                width:10px;
                height:10px;
                background:$red;
                top:4px;
                left:3px;
                opacity:0;
            }
        }

        &:checked + label {

            &:after {
                opacity:1;
            }
        }
    }
}

.pager-area {
    margin-top:40px;
    .inner {
        display:flex;
        align-items:center;
        justify-content:center;

        &.mobile-content {
            display:none;
        }

        .buttons-outer {
            display:flex;
            align-items:center;
            justify-content:flex-end;
            font-size:0px;
            line-height:0px;

            .arrow-button {
                min-width:7px;
                min-height:13px;

                &.double-arrow-button {
                    min-width:13px;
                }
            }

            .prev-button {
                margin-left:22px;
            }

            .next-button {
                margin-right:22px;
            }

            &.next-buttons {
                justify-content: flex-start;

                .arrow-button {
                    .icon {
                        transform:rotate(180deg);
                    }
                }
            }
        }

        .numbers {
            display:flex;
            justify-content:center;
            align-items:center;
            font-size:0px;
            line-height:0px;
            margin:0px 30px 0px 30px;

            .pager-item {
                text-decoration:none;
                color:$primary-color;
                display:flex;
                justify-content:center;
                align-items:center;
                background:$white;
                border:1px solid $white;
                transition:$border-color-transition, $bg-color-transition, $color-transition;
                height:29px;
                width:25px;
                margin:0px 3px 0px 3px;

                &.active {
                    color:$white;
                    background:$red;
                    border:1px solid $red;
                }
                
                .text {
                    font-weight:$bold-fweight;
                    font-size:14px;
                    line-height:20px;
                    letter-spacing:0.2px;
                }
            }

            .dots {
                font-weight:$bold-fweight;
                font-size:14px;
                line-height:20px;
                letter-spacing:0.2px;
                margin:0px 8px 0px 8px;
            }
        }
    }
}

.quantity-control-panel {
    display:flex;
    align-items:center;
    height:46px;

    .field-outer {
        width:110px;
        margin-right:4px;
        display:flex;

        .field {
            width:50px;
            text-align:center;
            border-left:none;
            border-right:none;
            padding:0px 4px 0px 4px;
            font-size:16px;
            line-height:22px;
            letter-spacing:0.2px;
        }

        .control-btn {
            width:30px;
            font-size:0px;
            line-height:0px;
            display:flex;
            justify-content:center;
            align-items:center;
            text-transform:none;
            text-decoration: none;
            border:1px solid $field-border-color;
            transition: $color-transition;
            color:$red;
            cursor:pointer;

            &.inactive {
                color: rgba(49,59,79,0.30);
            }

            span {
                font-size:18px;
                line-height:20px;
                font-weight:$bold-fweight;
            }
        }
    }

    .unit {
        font-size:12px;
        line-height:20px;
        letter-spacing:0.2px;
    }
}

.arukereso-section {
    max-width:1010px;
    width:calc(100% - 100px);
    margin:auto;
    padding-left:50px;
    
    .inner-content {
        color:$white;
        background:$blue;
        display:flex;
        text-align:left;
        position:relative;

        .arukereso-round-logo {
            background:url('../img/arukerreso-kerek-logo.png') no-repeat center center / 100% auto;
            width:93px;
            height:93px;
            position:absolute;
            left:0px;
            top:0px;
            bottom:0px;
            margin:auto;
            transform: translateX(-50%);
        }

        > .left-content {
            border-right:4px solid $white;
            padding:30px 24px 30px 61px;
            font-size:14px;
            line-height:20px;
            letter-spacing:0.2px;
            width:340px;
            display:flex;
            align-items:center;
            position:relative;

            
        }

        > .right-content {
            display:flex;
            align-items:center;
            justify-content:space-between;
            padding:23px 32px 20px 23px;
            width:calc(100% - 340px);

            > .left-content {
                .counter-row {
                    font-size:12px;
                    line-height:18px;
                    margin-bottom:4px;

                    .number {
                        font-weight:$bold-fweight;
                        font-size:16px;
                        line-height:18px;
                    }
                }

                .value-row {
                    display:flex;
                    align-items:center;
                    justify-content:flex-start;

                    .number {
                        font-size:28px;
                        line-height:28px;
                        letter-spacing:0.4px;
                        font-weight:$bold-fweight;
                    }

                    .text {
                        font-size:12px;
                        line-height:14px;
                        margin-left:5px;
                    }
                }
            }

            > .rating-items {
                .row {
                    display:flex;
                    margin-bottom:8px;

                    &:last-of-type {
                        margin-bottom:0px;
                    }

                    .label {
                        font-size:12px;
                        line-height:18px;
                        font-weight:500;
                        width:calc(100% - 81px);
                        padding-right:12px;
                    }

                    .stars-outer {
                        color:$yellow;
                        display:flex;
                        width:81px;
                        align-items:center;

                        .icon {
                            margin-right:2px;
                            min-width:11px;

                            &.big {
                                min-width:14px;
                            }
                            
                            &:last-of-type {
                                margin-right:0px;
                            }
                        }
                    }
                }
            }
        }
    }
}

body {
    .remodalwindow {
        font-family:$primary-font-family;
        color:$primary-color;
        padding:0px;
        
        > .inner-content {
            padding:48px 0px 57px 0px;
        }

        .close-btn {
            color:#969696;
            position:absolute;
            top:20px;
            right:20px;
            width:18px;
            height:17px;
            display:block;
        }

        h2.title {
            font-weight:500;
            margin-bottom:54px;
        }
    }

    .upsell-remodal {
        max-width:780px;

        > .inner-content {
            padding-bottom:20px;
        }

        .top-content {
            padding:0px 55px 0px 55px;

            .info-content {
                display:flex;
                align-items:center;
                justify-content:space-between;
                text-align:left;
                background:$light-green;
                padding:30px 30px 25px 25px;

                .button {
                    white-space:nowrap
                }

                .left-content {
                    padding-right:30px;
                    display:flex;
                    align-items:center;

                    .image-outer {
                        width:65px;
                        height:65px;
                        position:relative;
                        min-width:65px;
                        margin-right:20px;

                        .icon {
                            position:absolute;
                            top:0px;
                            left:0px;
                            right:0px;
                            bottom:0px;
                            z-index:2;
                            margin:auto;
                            width:32px;
                            height:32px;
                        }

                        img {
                            position:relative;
                            z-index:1;
                            max-height:100%;
                        }
                    }

                    .text {
                        max-width:280px;
                    }
                }
            }
        }

        .upsell-labels-outer {
            margin-top:42px;
            text-align:left;

            h3 {
                margin-bottom:23px;
                padding:0px 55px 0px 55px;
            }
            
            &:after {
                content:'';
                display:block;
                width:calc(100% - 110px);
                height:1px;
                background:$gray;
                margin:9px auto 0px auto;
            }

            .upsell-labels {
                display:flex;
                flex-wrap:wrap;
                padding:0px 55px 0px 55px;
            }

            .upsell-category-label {
                white-space: nowrap;
                font-weight:$bold-fweight;
                text-decoration:none;
                display:inline-flex;
                height:32px;
                align-items:center;
                font-size:14px;
                line-height:18px;
                border:1px solid $white;
                border-radius:$border-radius;
                padding:0px 11px 0px 11px;
                margin:13px 13px 0px 0px;
                transition:$border-color-transition;

                &.active {
                    border-color:$red;
                }

                &:last-of-type {
                    margin-right:0px;
                }
            }


        }

        .upsell-content {
            position:relative;
            text-align:left;
            overflow:hidden;

            .content-width {
                padding:0px 55px 0px 55px;
            }

            .product-slider-section {
                padding:30px 0px 37px 0px;
                .pager-outer {
                    width:55px;
                    
                    .pager {
                        width:34px;
                        height:34px;
                    }
                }

                .product-slider-outer {
                    width:calc(100% - 110px);

                    .slider-inner {
                        .product-card {
                            margin-right:32px;

                            &:last-of-type {
                                margin-right:0px;
                            }
                        }
                    }
                }
            }
        }
    }

    .profil-remodal {
        padding:0px 30px 0px 30px;
        max-width:480px;

        > .inner-content {
            padding:60px 0px 60px 0px;
        }

        &.unique-price-remodal {
            > .inner-content {
                padding:40px 0px 40px 0px;
            }
        }
    }

    .registration-remodal {
        max-width:860px;
    }
}

.profil-form {
    margin:0px auto 0px auto;
    &:not(.registration-form) {
        max-width:330px;
    }

    &.unique-price-form {
        max-width:100%;

        .form-content {
            max-width:330px;
            margin:auto;
        }

        .text-content {
            margin-bottom:44px;

            .lead {
                font-size:20px;
                line-height:28px;
                margin-top:18px;
            }
        }

        .checkbox-row {
            text-align:left;
        }
    }

    h1, .like-h1 {
        margin-bottom:16px;
    }

    .lead {
        text-align:center;
    }

    .text-content {
        margin-bottom:50px;
    }

    .row {
        text-align:left;
        margin-top:20px;

        &:first-of-type {
            margin-top:0px;
        }

        &:not(.checkbox-row) {
            label {
                font-size:10px;
                line-height:12px;
                margin-bottom:1px;
                font-weight:$bold-fweight;
                text-transform:uppercase;
                color:$primary-color;
                letter-spacing:0.6px;

                &.required {
                    &:after {
                        content:'*';
                        color:$red;
                    }
                }
            }
        }

        .field, .custom-select  {
            display:block;
            width:100%;
        }
    }

    .button-row {
        margin-top:38px;
    }

    .info-row {
        margin-top:20px;
    }

    &.registration-form {
        max-width:700px;

        .row {
            display:flex;
            flex-wrap:wrap;
            
            .divider {
                width:100%;
            }

            .col {
                margin-right:16px;
                margin-bottom:20px;

                &.small-col {
                    width:74px;
                }

                &.dynamic-col {
                    width:calc((100% - 196px) / 2);
                }

                &.street-number-col {
                    margin-right:0px;
                }

                &.phone-col {
                    margin-right:0px;
                    width:138px;
                }

                &.normal-col {
                    width:calc((100% - 170px) / 2);
                }
            }
        }

        .checkbox-row {
            margin-top:10px;
        }

        .button-row {
            margin-top:50px;
        }
    }

}

.text-opener-content {
    position:relative;
    overflow:hidden;

    &.active { 
        .text-opener-link {
            display:inline-flex;
            justify-content:flex-end;
            align-items:center;
        }
    }

    &.opened {
        padding-bottom:20px;

        .text-opener-link {
            .text {
                .close {
                    display:block;
                }

                .open {
                    display:none;
                }
            }

            .icon {
                transform:rotate(180deg);
            }
        }
    }

    .text-opener-link {
        display:none;
        position:absolute;
        bottom:0px;
        right:0px;
        background:$white;
        padding:0px 6px 0px 8px;
        z-index:2;
        font-size:0px;
        line-height:22px;
        letter-spacing:0px;
        text-decoration: none;

        .text {
            font-size:12px;
            letter-spacing:0.2;
            font-weight:$bold-fweight;

            .close {
                display:none;
            }
        }

        .icon {
            margin-left:6px;
            width:9px;
            height:6px;
            transition:$transform-transition;
        }
    }
}

@include resolution2X {
    .arukereso-section {
        .inner-content {
            .arukereso-round-logo {
                background-image: url('../img/arukerreso-kerek-logo-2x.png');
            }
        }
    }
}

@include resolution3X {
    .arukereso-section {
        .inner-content {
            .arukereso-round-logo {
                background-image: url('../img/arukerreso-kerek-logo-3x.png');
            }
        }
    }
}

@include hoverStatements {
    a {
        &:hover {
            text-decoration:none;
        }
    }
    .button {
        &:hover {
            background-color:$red;

            &.empty-button {
                background:$primary-button-background;
                color:$white;
            }
        }
    }

    .arrow-link {
        &:hover {
            .text {
                text-decoration:underline;
            }

            .icon-outer {
                margin-left:17px;
            }
        }
    }

    .pager-area {
        .inner {
            .numbers {
                .pager-item {
                    &:not(.active) {
                        &:hover {
                            border-color:$red;
                            color:$red;
                        }
                    }
                }
            }
        }
    }

    body {
        .upsell-remodal {
            .upsell-labels-outer {
                .upsell-category-label {
                    &:hover {
                        border-color:$red;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1280px) {
    .trust-section {
        padding-top:0px;
        .trust-item {
            &:not(.visible-from-mobile) {
                margin:15px 15px 0px 15px;
            }
        }
    }
}

@media screen and (max-width:1080px) {
    .arukereso-section {
        .inner-content {
            > .left-content {
                width:calc(100% - 580px);
            }

            > .right-content {
                padding:23px 23px 23px 23px;
                width:580px;

                .rating-items {
                    min-width:265px;
                }
            }
        }
    }
}

@include lying-tablet-breakpoint {
    body {
        .upsell-remodal {
            .top-content {
                padding:0px 30px 0px 30px;
            }

            .upsell-content {
                .content-width {
                    padding:0px 30px 0px 30px;
                }
                .product-slider-section {
                    padding:0px;
                    margin-top:25px;
                    .product-slider-outer {
                        overflow-y:hidden;
                        width:100%;
                    }
                }

                .slider-inner {
                    &:after {
                        content:'';
                        display:block;
                        height:1px;
                        width:30px;
                        min-width:30px;
                    }
                }
            }

            .upsell-labels-outer {
                margin-top:30px;

                h3 {
                    padding:0px 30px 0px 30px;
                    margin-bottom:27px;
                }

                &:after {
                    width:calc(100% - 60px);
                }

                .upsell-labels {
                    flex-wrap:nowrap;
                    padding:0px;
                    overflow-y:hidden;
                    overflow-x:auto;

                    &:before, &:after {
                        content:'';
                        height:1px;
                        width:30px;
                        min-width:30px;
                        display:block;
                    }
                }

                .upsell-category-label {
                    margin-top:0px;
                }
            }
        }
    }

    main {
        &.without-breadcrumb {
            padding-top:40px;
        }

        &.has-bottom-padding {
            padding-bottom:55px;
        }
    }
}

@include below-lying-tablet {
    .arukereso-section {
        width:calc(100% - 80px);

        .inner-content {
            .arukereso-round-logo {
                width:80px;
                height:80px;
            }

            > .left-content {
                width:45%;
                padding:23px 26px 23px 56px;

                
            }

            > .right-content {
                flex-wrap:wrap;
                width:55%;
                padding:20px 20px 20px 23px;

                > .left-content {
                    .value-row {
                        max-width:300px;
                        .number {
                            min-width:97px;
                        }
                    }
                }

                .rating-items {
                    margin-top:15px;

                    .row {
                        margin-bottom:0px;
                    }
                }
            }
        }
    }
}

@include below-standing-tablet {
    .arukereso-section {
        width:100%;
        padding-left:0px;

        .inner-content {
            flex-wrap:wrap;
            padding:22px 20px 24px 20px;
            
            .arukereso-round-logo {
                width:60px;
                height:60px;
                min-width:60px;
                left:20px;
                top:20px;
                bottom:auto;
                margin:0px;
                transform:none;
            }

            > .left-content {
                width:100%;
                border-right:none;
                display:flex;
                padding:0px 0px 0px 74px;
                margin-bottom:13px;
                align-items:flex-start;

                

                > .inner {
                    width:100%;
                    padding-left:0px;
                    font-size:12px;
                    line-height:18px;
                }
            }

            > .right-content {
                width:100%;
                padding:0px 0px 0px 74px;

                .rating-items {
                    display:none;
                }
            }
        }
    }
}

@include below-standing-tablet {
    body {
        .remodalwindow {
            h2.title {
                margin-bottom:40px;
            }
        }

        .upsell-remodal {
            .top-content {
                .cart-content {
                    margin-bottom:38px;
                }

                .button-row {
                    padding-bottom:45px;
                }
            }    
        }
    }
}

@include mobile {
    h2, .like-h2 {
        font-size:20px;
        line-height:24px;
    }

    h3, .like-h3 {
        font-size:16px;
        line-height:22px;
        font-weight:500;
    }

    h1, .like-h1 {
        font-size:20px;
        line-height:26px;
    }

    body {
        &:not(.home) {
            font-size:12px;
            line-height:18px;
        }
    }

    main {
        &.without-breadcrumb {
            padding-top:20px;
        }

        &.has-bottom-padding {
            padding-bottom:30px;
        }
    }

    .content-width {
        padding-left:20px;
        padding-right:20px;
    }

    .product-tag {
        height:19px;
        font-size:10px;
        line-height:10px;
    }

    .warranty-tag {
        height:20px;
        font-size:10px;
        line-height:10px;
        padding:0px 2px 0px 5px;

        &:after {
            border-width:10px 0px 10px 5px;
        }
    }

    .shipping-tag {
        height:14px;

        .icon {
            width:16px;
            height:10px;
        }

        .text {
            font-size:10px;
            line-height:12px;
            letter-spacing:0.1px;
            margin-left:4px;
        }
    }

    .trust-section {
        padding:0px;
        
        .content-width, > .inner-content {
            height:46px;
            align-items:center;
        }

        .trust-item {
            display:none!important;
            
            &.show-on-mobile {
                display:flex!important;
                margin:0px;
            }
        }
    }

    input[type="radio"] {
        &:not(.method-selector) {
            & + label {
                font-size:12px;
            }
        }
    }

    .breadcrumb {
        margin:6px 0px 15px 0px;

        ul {
            li {
                display:none;

                &:last-of-type, &.penultimate-item {
                    display:flex;
                }

                &.penultimate-item {
                    a {
                        .name {
                            display:none;
                        }

                        .mobile-label {
                            display:block;
                        }
                    }
                }
            }
        }
    }

    .pager-area {
        .inner {
            &.desktop-content {
                display:none;
            }

            &.mobile-content {
                display:flex;
                justify-content:center;
                align-items:center;

                a {
                    display:flex;
                    justify-content: center;
                    align-items:center;
                    width:100%;
                    height:44px;
                    text-decoration: none;
                    font-size:0px;
                    line-height:0px;

                    .text {
                        font-size:14px;
                        line-height:16px;
                        letter-spacing: 0.2px;
                        font-weight:$bold-fweight;
                        margin-right:10px;
                    }
                }
            }
        }
    }

    .text-opener-content {
        .text-opener-link {
            line-height:18px;
        }
    }

    body {
        .remodalwindow {
            .close-btn {
                width:15px;
                height:14px;
                top:15px;
                right:15px;

                .icon {
                    width:100%;
                    height:100%;
                }
            }
        }

        .upsell-remodal {
            > .inner-content {
                padding-bottom:0px;
            }

            h2.title {
                margin-bottom:20px
            }
            
            .top-content {
                padding:0px;

                .info-content {
                    flex-wrap:wrap;
                    justify-content:center;
                    padding:20px 15px 20px 15px;

                    .left-content {
                        width:100%;
                        margin-bottom:20px;
                        padding-right:0px;

                        .text {
                            max-width:none;
                        }
                    }
                }
            }

            .upsell-labels-outer {
                h3 {
                    padding:0px 15px 0px 15px;
                    margin-bottom:20px;
                }

                .upsell-labels {
                    &:before, &:after {
                        width:15px;
                        min-width:15px;
                    }
                }
        
                .upsell-category-label {
                    padding:0px 8px 0px 8px;
                }

                &:after {
                    width:calc(100% - 30px);
                }
            }

            .product-slider-outer {
                .slider-inner {
                    margin-top:0px;
                }
            }
        }

        .profil-remodal {
            padding:0px 20px 0px 20px;

            > .inner-content {
                padding:40px 0px 50px 0px;
            }
        }
    }

    .profil-form {
        h1, .like-h1 {
            margin-bottom:10px;
        }

        .text-content {
            margin-bottom:30px;
        }

        .row, .info-row {
            margin-top:15px;
        }

        .button-row {
            margin-top:30px;
        }

        &.registration-form {
            .row {
                .col {
                    margin-right:0px;
                    margin-bottom:15px;

                    &.normal-col, &.phone-col, &.street-col {
                        width:100%!important;
                    }

                    &.dynamic-col {
                        width:calc(100% - 90px);
                    }

                    &.street-number-col, &.city-col {
                        margin-left:16px;
                    }
                }
            }

            .checkbox-row {
                text-align:left;
                margin-top:5px;
            }

            .button-row {
                margin-top:30px;
            }
        }

        &.unique-price-form {
            .text-content {
                margin-bottom:30px;
                .lead {
                    font-size:16px;
                    line-height:22px;
                }
            }
        }
    }

    .field {
        padding:0px 17px 0px 11px;
        font-size:16px;

        &.valid, &:invalid, &.has-error {
            background-position: right 6px top 18px;
        }
    }
}

@media screen and (max-width:359px) {
    .arukereso-section {
        .inner-content {
            > .left-content {
                min-height:60px;
            }

            > .right-content {
                padding-left:0px;
            }
        }
    }
}