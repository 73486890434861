@import 'inc/all.scss';
@import 'inc/swiper.scss';
@import './inc/style.scss';

.home-page-content {
    overflow:hidden;

    .mobile-categories-section {
        display:none;
    }

    hr {
        width:calc(100% - 60px);
        max-width:1240px;
        color:$gray;
        margin:0px auto 65px auto;
        border:none;
        height:1px;
        background:$gray;
    }

    .slider-section {
        max-width:1240px;
        margin:auto;
        display:flex;
        justify-content:flex-end;

        .slider-container {
            width:calc(100% - 285px);
            max-width:1240px;
            height:434px;
            margin:0px;
            display:flex;
            align-items:center;
            position:relative;
            z-index:11;
        }

        .swiper-container {
            ul {
                list-style:none;
                width:100%;
                height:100%;
                margin:0px;
                padding:0px;
                border:none;

                li {
                    display:flex;
                    align-items:center;
                    padding:0px;
                    margin:auto;

                    img {
                        width:100%;
                    }

                    .slider-inner {
                        width:100%;
                    }
                }
            }


            .swiper-pagination {
                font-size:0px;
                line-height:0px;
                display:flex;
                justify-content:flex-start;
                align-items:center;
                bottom:20px;
                left:20px;
                padding:0px;
                margin:auto;

                .swiper-pagination-bullet {
                    width:11px;
                    height:11px;
                    border-radius:50%;
                    background:none;
                    margin:0px 9px 0px 0px;
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    opacity:1;

                    &:before {
                        content:'';
                        display:block;
                        background-color:$dark-gray;
                        width:7px;
                        height:7px;
                        margin:0px;
                        transition:all $transition-duration $transition-timing;
                        border-radius:50%;
                    }

                    &.swiper-pagination-bullet-active {
                        &:before {
                            width:11px;
                            height:11px;
                            background:$red;
                        }
                    }
                }
            }
        }
    }

    .trust-section, .arukereso-section {
        margin-bottom:65px;
    }

    .header-trust-section {
        background:$white;
        padding:0px;
        margin:20px 0px 0px 0px;

        .content-width {
            justify-content:flex-start;
        }

        .trust-item {
            &:not(.visible-from-mobile) {
                margin:0px 35px 0px 0px;
            }
        }
    }

    .brands-section {
        background:$light-gray;
        min-height:110px;
        margin:35px 0px 35px 0px;

        .content-width {
            display:flex;
            justify-content:center;
            flex-wrap:wrap;
            align-items:center;

            &.images-in-one-row {
                justify-content:space-between;
                flex-wrap:nowrap;
            }
        }

        .brand-item {
            background-color:$light-gray;
            height:110px;
            padding:22px;
            display:flex;
            align-items:center;
            transition:$bg-color-transition;
            text-decoration:none;
            font-size:14px;
            line-height:14px;
            letter-spacing:2px;
            color:$primary-color;
            font-weight:500;

            img {
                max-height:45px;
            }

            &.hide-in-one-row {
                display:none;
            }
        }

        .all-button {
            display:block;
            width:68px;
            height:62px;
            min-width:68px;
            background-color:$white;
            color:$red;
            transition: $bg-color-transition, $color-transition;
            font-size:0px;
            line-height: 0px;
            text-decoration:none;
            letter-spacing: 0px;
            padding:15px 11px 15px 11px;
            margin-left:5px;

            .text {
                font-size:12px;
                line-height:16px;
                letter-spacing:0.2px;
                font-weight:$bold-fweight;
            }

            .icon {
                display:inline;
                margin-left:2px;
            }
        }
    }

    .product-slider-section {
        margin-bottom:65px;
    }

    .blog-section {
        .content-width {
            display:flex;
            justify-content:space-between;
            align-items:center;
        }

        .title-box {
            width:110px;

            .arrow-link {
                margin-top:5px;
                display:block;
            }
        }
        
        .articles-content {
            width:calc(100% - 110px);
            padding-left:45px;
            display:flex;
            align-items:flex-start;

            .article-box {
                display:flex;
                align-items:flex-start;
                font-size:0px;
                line-height:0px;
                text-decoration:none;
                color:$primary-color;
                width:calc((100% - 50px) / 3);
                margin-right:25px;
                &:last-of-type {
                    margin-right:0px;
                }

                .image-content {
                    width:150px;
                }

                .text-content {
                    width:calc(100% - 150px);
                    padding-left:15px;

                    > span {
                        display:block;
                    }

                    .date {
                        color:$secondary-color;
                        font-size:12px;
                        line-height:14px;
                        margin-bottom:9px;
                    }

                    .title {
                        font-size:14px;
                        line-height:18px;
                        font-weight:$bold-fweight;
                        margin-bottom:3px;
                    }

                    > .text {
                        font-size:14px;
                        line-height:20px;
                        overflow:hidden;
                    }

                    .arrow-link {
                        margin-top:8px;
                        color:$red;
                    }
                }
            }
        }
    }

    .about-us-section {
        padding:65px 0px 65px 0px;

        .content-width {
            display:flex;
            justify-content:space-between;
            align-items:flex-start;
        }

        .about-content {
            width:calc(100% - 600px);
            max-width:610px;

            h3 {
                margin-bottom:19px;
            }

            .text {
                p {

                }
            }
        }

        .help-content {
            width:550px;
            background:$light-gray;

            .inner {
                width:100%;
                margin:auto;
                padding:45px 30px 45px 30px;
                max-width:500px;
            }

            h3 {
                margin-bottom:10px;
            }

            .text {
                a {
                    font-weight:$bold-fweight;
                    text-decoration:none;
                }
            }
        }
    }
}

@include hoverStatements {
    .home-page-content {
        .brands-section {
            .brand-item {
                &:hover {
                    background-color:$white;
                }
            }

            .all-button {
                &:hover {
                    color:$white;
                    background:$red;
                }
            }
        }
    }
}

@media screen and (max-width:1300px) {
    .home-page-content {
        .slider-section {
            max-width:100%;
            padding-left:30px;
        }

        .brands-section {
            min-height: auto;

            .content-width {
                max-width:900px;
                justify-content:center;

                &.images-in-one-row {
                    justify-content: center;
                    flex-wrap:wrap;
                }
            }

            .brand-item {
                height:90px;
                padding:20px 20px 20px 20px;

                &.hide-in-one-row {
                    display:flex;
                }
            }
        }
    }
}

@media screen and (max-width:1280px) {
    .home-page-content {
        .header-trust-section {
            margin-top:5px;

            .content-width {
                justify-content:center;
            }

            .trust-item {
                &:not(.visible-from-mobile) {
                    margin:15px 15px 0px 15px; 
                }
            }
        }

        

        .blog-section {
            .articles-content {
                .article-box {
                    width:calc(50% - 15px);
                    margin-left:30px;
                    margin-right:0px;

                    &:first-of-type {
                        margin-left:0px;
                    }

                    &:last-of-type {
                        display:none;
                    }
                }
            }
        }
    }
}

@include showMobileMenu {
    .home-page-content {
        .slider-section {
            padding-left:0px;
            .slider-container {
                padding-left:0px;
                height:auto;
                width:100%;

                .slider-inner {
                    img {
                        width:100%;
                    }
                }
            }
        }
    }
}

@include below-lying-tablet {
    .home-page-content {
        .blog-section {
            .articles-content {
                padding-left:25px;

                .article-box {
                    width:calc(50% - 10px);
                    margin-left:20px;

                    .image-content {
                        width:100px;
                    }

                    .text-content {
                        width:calc(100% - 100px);
                    }
                }
            }
        }

        .about-us-section {
            .about-content {
                width:calc(50% - 15px);
            }

            .help-content {
                width:calc(50% - 15px);

                .inner {
                    padding:30px;
                }
            }

        }
    }
}

@include below-standing-tablet {
    .home-page-content {
        .blog-section {
            border-bottom:1px solid $gray;
            .content-width {
                flex-wrap:wrap;
                padding:0px 0px 46px 0px;
                position:relative;
            }
            
            .title-box {
                width:100%;
                padding:0px 30px 0px 30px;

                .arrow-link {
                    position:absolute;
                    left:0px;
                    right:0px;
                    bottom:15px;
                    margin:auto;
                    width:100%;
                    text-align:center;
                    
                    .icon-outer {
                        right:32px;
                        position:absolute;
                        bottom:4px;
                        margin:0px;
                    }
                }
            }

            .articles-content {
                width:100%;
                padding-left:0px;
                flex-wrap:wrap;

                .article-box {
                    padding:25px 30px 25px 30px;
                    width:100%;
                    margin:0px;
                    border-bottom:1px solid $gray;
                }
            }
        }

        .about-us-section {
            padding-bottom:40px;
            .content-width {
                padding:0px;
                flex-wrap: wrap;

                .about-content {
                    width:100%;
                    padding:0px 30px 40px 30px;
                    max-width:none;
                }

                .help-content {
                    width:100%;
                    .inner {
                        max-width:none;
                    }
                }
            }
        }
    }
}

@include mobile {
    .home-page-content {
        hr {
            display:none;
        }

        .arukereso-section, .trust-section {
            margin-bottom:35px;
        }

        .header-trust-section {
            background:$light-gray;
            margin:0px;
        }

        .brands-section {
            margin:5px 0px 30px 0px;
            background:$white;

            .content-width {
                padding:0px;
            }

            .brand-item {
                padding: 7px 10px 7px 10px;
                width:33.33%;
                background-color:$white;
                justify-content:center;
                height:50px;
                max-height:none;

                img {
                    max-height:100%;
                }
            }

            .all-button {
                width:100%;
                height:48px;
                position:relative;
                border-top:1px solid $gray;
                border-bottom:1px solid $gray;
                padding:14px 0px 14px 0px;
                text-align:center;
                margin:7px 0px 0px 0px;

                .text {
                    width:100%;
                    text-align:center;
                }

                .icon {
                    width:5px;
                    height:8px;
                    position:absolute;
                    top:0px;
                    right:22px;
                    bottom:0px;
                    margin:auto;
                }
            }
        }

        .product-slider-section {
            margin-bottom:0px;

            &.categories-best-section {
                margin-top:35px;
            }
        }

        .blog-section {
            padding-top:20px;
            .articles-content {
                .article-box {
                    padding:15px 20px 15px 20px;

                    .image-content {
                        width:85px;
                    }

                    .text-content {
                        width:calc(100% - 85px);
                    }
                }
            }

            .title-box {
                padding:0px 20px 0px 20px;
                .arrow-link {
                    .icon-outer {
                        right:22px;
                    }
                }
            }
        }

        .about-us-section {
            padding:22px 0px 27px 0px;

            .content-width {
                .about-content {
                    padding:0px 20px 20px 20px;
                }

                .help-content {
                    .inner {
                        padding:20px;
                        h3 {
                            margin-bottom:4px;
                        }
                    }
                }
            }
        }

        .slider-section {
            display:none;
        }

        .mobile-categories-section {
            display:flex;
            justify-content:center;
            align-items:flex-start;
            flex-wrap:wrap;
            padding:20px 15px 10px 15px;

            .category-item {
                font-size:0px;
                line-height:0px;
                text-decoration: none;
                width:calc((100% - 40px) / 4);
                margin:0px 5px 20px 5px;
                display:block;

                .image-outer {
                    display:flex;
                    justify-content:center;
                    align-items:flex-end;
                    height:55px;
                    width:100%;


                    img {
                        &.bojler {
                            width:24px;
                        }

                        &.csaptelep {
                            width:43px;
                        }

                        &.elektromos-futes {
                            width:42px;
                        }

                        &.elektromos-gazkazan {
                            width:33px;
                        }

                        &.futesi-tarolok {
                            width:27px;
                        }

                        &.kazan-gazkazan {
                            width:30px;
                        }

                        &.kemeny {
                            width:38px;
                        }

                        &.radiator {
                            width:48px;
                        }

                        &.szerelveny {
                            width:44px;
                        }

                        &.szivattyu {
                            width:45px;
                        }

                        &.szobatermosztat {
                            width:46px;
                        }

                        &.vegyes-tuzelesu-kazan {
                            width:30px;
                        }
                    }
                }

                .name {
                    display:block;
                    text-align:center;
                    color:$primary-color;
                    font-size:12px;
                    line-height:14px;
                    letter-spacing: 0.17px;
                    font-weight:$bold-fweight;
                    margin-top:7px;
                }
            }
        }
    }
}

@media screen and (max-width:359px) {
    .home-page-content {
        .mobile-categories-section {
            .category-item {
                width:calc((100% - 30px) / 3);
            }
        }
    }
}