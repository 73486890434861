@import 'colors.scss';
@import 'mixins.scss';

$primary-font-family: 'Fira Sans', Arial, sans-serif;

$primary-fsize: 14px;
$primary-lheight: 22px;

$secondary-fsize: 16px;
$secondary-lheight: 26px;

$mini-fsize: 12px;
$mini-lheight: 18px;

$thin-content: 800px;
$content-width: 1020px;
$wide-content: 1300px;

$medium-fweight: 500;
$bold-fweight: 700;

$border-radius: 2px;

$transition-duration: 0.25s;
$transition-timing: ease;

$transform-transition: transform $transition-duration $transition-timing;
$bg-color-transition: background-color $transition-duration $transition-timing;
$border-color-transition: border-color $transition-duration $transition-timing;
$opacity-transition: opacity $transition-duration $transition-timing;
$color-transition: color $transition-duration $transition-timing;
$box-shadow-transition: box-shadow $transition-duration $transition-timing;