@import 'inc/all.scss';

footer {
    color: $secondary-color;

    a {
        color:inherit;
    }

    > .top {
        padding:56px 0px 14px 0px;
        background:$light-gray;

        .title {
            font-size:0px;
            line-height:0px;
            
            .text {
                font-size:14px;
                line-height:20px;
                font-weight: $bold-fweight;
                color:$primary-color;
            }

            .icon {
                display:none;
            }
        }

        .link-cloud {
            .link-cloud-inner  {
                display:flex;
                justify-content:flex-start;
                align-items:flex-start;
            }
            
            .group {
                margin-right:80px;

                &:last-of-type {
                    margin-right:0px;
                }

                ul {
                    list-style:none;
                    margin:17px 0px 0px 0px;
                    padding:0px;

                    li {
                        font-size:12px;
                        line-height:16px;
                        margin-bottom:8px;

                        &:last-of-type {
                            margin-bottom:0px;
                        }

                        a {
                            text-decoration:none;
                        }

                        &.profil-item {
                            display:inline-flex;
                            justify-content:flex-start;
                            align-items:center;
                            color:$red;
                            font-weight:$bold-fweight;
                            
                            a {
                                color:$red;
                            }

                            .separator {
                                margin:0px 2px 0px 2px;
                            }

                            .icon {
                                margin-right:2px;
                            }
                        }
                    }
                }

                &.categories-group {

                    ul {
                        column-count: 3;
                        column-gap:35px;

                        li {
                            max-width:160px;
                        }
                    }
                }
            }
        }

        .copyright-content {
            margin-top:50px;
            display:flex;
            justify-content:flex-start;
            align-items: center;

            .copyright {
                margin-left:23px;
                font-size:12px;
                line-height:20px;
            }

            .logo-content {
                width:72px;
                height:20px;
                font-size:0px;
                line-height:0px;
                display:block;
                transform:translateY(-1px);

                .icon {
                    width:100%;
                    height:100%;
                }
            }
        }
    }

    > .bottom {
        padding:10px 0px 10px 0px;
        
        .content-width {
            display:flex;
            justify-content:space-between;
            align-items: flex-start;
        }

        .awards-content {
            display:flex;
            justify-content:flex-start;
            align-items:center;
            width:565px;

            .award-item {
                margin-right:38px;
                display:flex;
                justify-content:flex-start;
                align-items:flex-end;
                font-size:0px;
                line-height:0px;
                text-decoration:none;
                
                &:last-of-type {
                    margin-right:0px;
                }

                .text {
                    margin-left:7px;
                    font-size:10px;
                    line-height:12px;
                    letter-spacing:0.2px;
                }
            }
        }

        .partners-content {
            display:flex;
            flex-wrap:wrap;
            justify-content:flex-end;
            align-items:center;

            .partner-item {
                margin-left:20px;
            }
        }
    }

    &.small-footer {
        padding-bottom:15px;
        
        > .bottom {
            border-top:1px solid $gray;

            > .content-width {
                align-items:center;
                display:flex;
            }

            .left-content {
                display:flex;
                justify-content:flex-start;
                align-items:flex-end;
                width:320px;

                .logo-content {
                    margin-right:23px;
                    width:72px;
                    height:20px;

                    .icon {
                        width:100%;
                        height:100%;
                    }
                }

                .copyright {
                    font-size:12px;
                    line-height:16px;
                }
            }

            .partners-content {
                width:calc(100% - 320px);
                align-items:center;
                justify-content:flex-end;

                .partner-item {
                    margin:0px 0px 0px 16px;
                }
            }
        }
    }
}

@include hoverStatements {
    footer {
        a {
            &:hover {
                color:$red;
            }
        }
    }
}

@media screen and (max-width:1080px) {
    footer {
        > .top {
            .link-cloud {
                .group {
                    margin-right:50px;
                }
            }
        }

        > .bottom {
            .awards-content {
                width:525px;
                .award-item {
                    margin-right:18px;
                }
            }

            .partners-content {
                .partner-item {
                    margin-left:15px;
                }
            }
        }
    }
}

@include below-lying-tablet {
    footer {
        > .top {
            padding-top:0px;
            .link-cloud {
                padding:0px;
                display:block;

                .link-cloud-inner {
                    display:block;
                }

                .group {
                    width:100%;
                    margin:0px;
                    padding:0px 30px 0px 30px;
                    border-bottom:1px solid $gray;

                    .title {
                        padding:20px 0px 20px 0px;
                        position: relative;
                        
                        .icon {
                            display:block;
                            position:absolute;
                            transition:$transform-transition;
                            top:0px;
                            right:0px;
                            bottom:0px;
                            margin:auto;
                        }
                    }

                    &.active {
                        .title {
                            .icon {
                                transform: rotate(180deg);
                            }
                        }
                    }

                    .content {
                        display:none;
                        padding-bottom:30px;
                    }
                    
                    &.categories-group ul, ul {
                        column-count: 3;
                        column-gap:30px;
                    }
                }
            }
        }

        > .bottom {
            .content-width {
                display:block;
            }

            .awards-content {
                width:100%;
                justify-content:center;
            }

            .partners-content {
                padding-top:10px;
                width:100%;
                justify-content: center;
                flex-wrap: wrap;

                .partner-item {
                    margin:20px 10px 0px 10px; 
                }
            }
        }

        &.small-footer {
            > .bottom {
                > .content-width {
                    display:block;
                }
    
                .left-content {
                    width:100%;
                }
    
                .partners-content {
                    width:100%;
                    justify-content:center;
    
                    .partner-item {
                        margin:15px 4px 0px 4px;
                    }
                }
            }
        }
    }
}

@include mobile {
    footer {
        > .top {
            .link-cloud {
                .group {
                    padding:0px 20px 0px 20px;

                    .title {
                        padding:12px 0px 12px 0px;
                    }

                    &.categories-group ul, ul {
                        margin-top:10px;
                        column-count: 2;
                        column-gap:20px;
                    }
                }
            }

            .copyright-content {
                margin-top:16px;
            }
        }

        > .bottom {
            .content-width {
                padding:0px;
            }
            .awards-content {
                justify-content:flex-start;
                align-items:flex-start;
                flex-wrap: wrap;
                

                .award-item {
                    margin:0px 0px 12px 20px;

                    &:last-of-type {
                        margin-bottom:0px;
                    }
                }
            }
        }

        &.small-footer {
            .left-content {
                padding:0px 15px 0px 15px;
            }

            .partners-content {
                padding:0px 11px 0px 11px;
            }
        }
    }
}

@media screen and (max-width:359px) {
    footer {
        > .top {
            .link-cloud {
                .group {
                    &.categories-group ul, ul {
                        column-count: 1;
                        column-gap:0px;
                    }
                }
            }
        }
    }
}