$lying-tablet-width: 1024px;
$standing-tablet-width: 768px;
$mobile-breakpoint: 600px;

@mixin transform($transform...) {
    -webkit-transform: $transform;
    transform: $transform;
}

@mixin lying-tablet-breakpoint {
    @media screen and (max-width: #{$lying-tablet-width}) {
        @content;
    }
}

@mixin below-lying-tablet {
    @media screen and (max-width: #{$lying-tablet-width - 1px}) {
        @content;
    }
}

@mixin standing-tablet-breakpoint {
    @media screen and (max-width: #{$standing-tablet-width}) {
        @content;
    }
}

@mixin below-standing-tablet {
    @media screen and (max-width: #{$standing-tablet-width - 1px}) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: #{$mobile-breakpoint}) {
        @content;
    }
}

@mixin hoverStatements($content...) {
    @media all and (min--moz-device-pixel-ratio:0) {@content;}
    @media (hover:hover) {@content;}
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {@content;}
}

@mixin showMobileMenu($content...) {
    @media (pointer: coarse) {
        @content;
    }

    @media screen and (max-width:1024px) {
        @content;
    }
}

@mixin placeholder {
    ::-webkit-input-placeholder {
        @content;
    }

    :-moz-placeholder {
        @content;
    }

    ::-moz-placeholder {
        @content;
    }

    :-ms-input-placeholder {
        @content;
    }
}


@mixin resolution2X {
    @media only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
        @content;
    }
}

@mixin resolution3X {
    @media only screen and (-o-min-device-pixel-ratio: 9/4),
        only screen and (-webkit-min-device-pixel-ratio: 2.25),
        only screen and (min-device-pixel-ratio: 2.25),
        only screen and (min-resolution: 2.25dppx) {
        @content;
    }
}

@mixin forFirefox($content...) {
    @media all and (min--moz-device-pixel-ratio:0) {@content;}
}

@mixin showMobileFilter($content...) {
    @media (pointer: coarse) and (max-width:1280px) {
        @content;
    }

    @media screen and (max-width:1024px) {
        @content;
    }
}