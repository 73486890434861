/* ==========================================================================
   Remodal's default mobile first theme
   ========================================================================== */

/* Default theme styles for the background */

.remodalwindow-bg.remodalwindow-is-opening,
.remodalwindow-bg.remodalwindow-is-opened {
  filter: blur(3px);
}

/* Default theme styles of the overlay */

.remodalwindow-overlay {
  background: rgba(49,59,79,0.60);
}

.remodalwindow-overlay.remodalwindow-is-opening,
.remodalwindow-overlay.remodalwindow-is-closing {
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.remodalwindow-overlay.remodalwindow-is-opening {
  animation-name: remodalwindow-overlay-opening-keyframes;
}

.remodalwindow-overlay.remodalwindow-is-closing {
  animation-name: remodalwindow-overlay-closing-keyframes;
}

/* Default theme styles of the wrapper */

.remodalwindow-wrapper {
  padding: 10px 10px 0;
}

/* Default theme styles of the modal dialog */

.remodalwindow {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
  padding: 35px;

  transform: translate3d(0, 0, 0);

  color: #2b2e38;
  background: #fff;

  background: #FFFFFF;
  box-shadow: 0 4px 16px 4px rgba(0,0,0,0.30);

}

.remodalwindow.remodalwindow-is-opening,
.remodalwindow.remodalwindow-is-closing {
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.remodalwindow.remodalwindow-is-opening {
  animation-name: remodalwindow-opening-keyframes;
}

.remodalwindow.remodalwindow-is-closing {
  animation-name: remodalwindow-closing-keyframes;
}

/* Vertical align of the modal dialog */

.remodalwindow,
.remodalwindow-wrapper:after {
  vertical-align: middle;
}

/* Close button */

.remodalwindow-close {
  position: absolute;
  top: 0;
  left: 0;

  display: block;
  overflow: visible;

  width: 35px;
  height: 35px;
  margin: 0;
  padding: 0;

  cursor: pointer;
  transition: color 0.2s;
  text-decoration: none;

  color: #95979c;
  border: 0;
  outline: 0;
  background: transparent;
}

.remodalwindow-close:hover,
.remodalwindow-close:focus {
  color: #2b2e38;
}

.remodalwindow-close:before {
  font-family: Arial, "Helvetica CY", "Nimbus Sans L", sans-serif !important;
  font-size: 25px;
  line-height: 35px;

  position: absolute;
  top: 0;
  left: 0;

  display: block;

  width: 35px;

  content: "\00d7";
  text-align: center;
}

/* Dialog buttons */

.remodalwindow-confirm,
.remodalwindow-cancel {
  font: inherit;

  display: inline-block;
  overflow: visible;

  min-width: 110px;
  margin: 0;
  padding: 12px 0;

  cursor: pointer;
  transition: background 0.2s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;

  border: 0;
  outline: 0;
}

.remodalwindow-confirm {
  color: #fff;
  background: #81c784;
}

.remodalwindow-confirm:hover,
.remodalwindow-confirm:focus {
  background: #66bb6a;
}

.remodalwindow-cancel {
  color: #fff;
  background: #e57373;
}

.remodalwindow-cancel:hover,
.remodalwindow-cancel:focus {
  background: #ef5350;
}

/* Remove inner padding and border in Firefox 4+ for the button tag. */

.remodalwindow-confirm::-moz-focus-inner,
.remodalwindow-cancel::-moz-focus-inner,
.remodalwindow-close::-moz-focus-inner {
  padding: 0;

  border: 0;
}

/* Keyframes
   ========================================================================== */

@keyframes remodalwindow-opening-keyframes {
  from {
    transform: scale(1.05);

    opacity: 0;
  }
  to {
    transform: none;

    opacity: 1;

    filter: blur(0);
  }
}

@keyframes remodalwindow-closing-keyframes {
  from {
    transform: scale(1);

    opacity: 1;
  }
  to {
    transform: scale(0.95);

    opacity: 0;

    filter: blur(0);
  }
}

@keyframes remodalwindow-overlay-opening-keyframes {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes remodalwindow-overlay-closing-keyframes {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Media queries
   ========================================================================== */

@media only screen and (min-width: 641px) {
  .remodalwindow {
    max-width: 700px;
  }
}

/* IE8
   ========================================================================== */

.lt-ie9 .remodalwindow-overlay {
  background: #2b2e38;
}

.lt-ie9 .remodalwindow {
  width: 700px;
}
