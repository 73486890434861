$primary-color:#313b4f;
$secondary-color: #6E7583;

$white: #ffffff;

$light-gray: #f4f5f8;
$gray: #e3e7ea;
$dark-gray: #CBCBCB;
$dark-gray-2:#969696;

$light-blue: #EEF8FF;
$blue: #0e96fc;

$light-red: #F6E5E1;
$red: #da0d0e;

$yellow: #FFDD61;
$orange: #F4A437;

$light-green: #F2FBF5;
$green: #09B53E;

$border-color: $gray;
$field-border-color: #D3DAE2;

$primary-button-background:$blue;