@import 'all.scss';

.search-box-outer {
    background:$white;
    position:absolute;
    width:510px;
    height:40px;
    top:7px;
    left:0px;
    right:0px;
    margin:auto;
    border-radius:$border-radius;
    
    > form {
        padding:0px 14px 0px 0px;
        display: flex;
        align-items:center;
        justify-content: flex-start;
        height:100%;
    }

    .field {
        width:calc(100% - 19px);
        border:none;
        height:100%;
        letter-spacing:0.2px;
        font-size:12px;
        line-height:18px;
        padding:12px 14px 10px 14px;
    }

    .search-button {
        width:19px;
        height:19px;
        border:none;
        background:transparent;
        padding:0px;
        margin:0px;
        box-shadow: none;
        outline:none;
        font-size:0px;
        line-height:0px;
        color:$red;
    }

    #pf-203c796f-c33e-4204-9bce-8be0348027f3.pf-suggestion-container {
        width:calc(100% + 480px);
        left:0px;
        transform: translateX(-240px);
        top:48px;
        background: #FFFFFF;
        box-shadow: 0 6px 20px 6px rgba(49,59,79,0.20);
        box-sizing:border-box;
        padding:27px 42px 34px 42px;
        border:none;
        
        &.open {
            display:flex;

            &:before {
                width:0px;
                height:0px;
                margin:0px;
                border-style: solid;
                border-width: 0px 8px 8px 8px;
                border-color: transparent transparent $white transparent;
                left:270px;
            }

            &:after {
                display:none!important;
            }
        }

        .pf-col-0 {
            width: 190px;
            border:none;
            float:none;
        }

        

        .pf-group-title {
            padding:0px 8px 0px 8px;
            font-size:16px;
            line-height:18px;
            font-weight:$bold-fweight;
            color:$primary-color;
            text-transform:none;
            margin-bottom:6px;
            border-bottom:none;
        }

        .pf-suggestion-list {
            li {
                .pf-suggestion {
                    padding:10px 8px 10px 8px;
                    border:none;
                    font-weight:400;
                    color:$primary-color;
                    font-size:14px;
                    line-height: 18px;

                    &.pf-selected {
                        background:$gray;
                    }

                    .pf-suggestion-body {
                        .pf-suggestion-body-text1 {
                            font-size:14px;
                            line-height:18px;
                            color:$red;
                            font-weight:$bold-fweight;

                            span {
                                &.pf-highlight {        
                                    font-weight:400;
                                    color:$primary-color;
                                }
                            }
                        }
                    }
                }
            }    
        }

        .pf-col-1 {
            width:calc(100% - 230px);
            margin-left:40px;
            border:none;
            float:none;

            .pf-suggestion-list {
                padding:0px;

                li {
                    border-bottom:1px solid $gray;
                    
                    &:last-of-type {
                        border-bottom:0px;
                    }

                    .pf-suggestion {
                        padding:8px 8px 8px 8px;
                        align-items:center;
                        display:flex;
                        
                        .pf-suggestion-left {
                            width:55px;
                            min-width:55px;
                            padding:0px;
                            background:$white;
                            height:100%;
                            display:flex;
                            justify-content:center;
                            align-items:center;

                            img {
                                margin:auto;
                                max-width:100%;
                                max-height:55px;
                            }
                        }

                        .pf-suggestion-body {
                            display:block;
                            width:calc(100% - 150px);
                            padding:17px 5px 17px 20px;
                        }

                        .pf-suggestion-right {
                            width:95px;
                            display:block;

                            .pf-suggestion-right-price {
                                font-size:16px;
                                line-height:18px;
                                font-weight:$bold-fweight;
                                color:$primary-color;
                            }

                            .pf-suggestion-right-unit-price {
                                color:$green;
                                font-size:12px;
                                line-height:16px;
                                font-weight:$bold-fweight;
                                margin-top:5px;
                                position:relative;
                                display:inline-block;

                                &:before {
                                    width:16px;
                                    height:16px;
                                    content:'';
                                    display:block;
                                    font-size:0px;
                                    line-height:0px;
                                    background:url('../../assets/img/svg/green-list-icon.svg') no-repeat center center / 100% auto;
                                    position:absolute;
                                    top:0px;
                                    left:0px;
                                    transform:translateX(-19px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.main-header {
    > .top {
        .search-box-outer {
            
        }
    }
}

@media screen and (max-width:1180px) {
    .search-box-outer {
        margin:auto 0px auto 0px;
        left:220px;
        width:calc(100% - 550px);

        #pf-203c796f-c33e-4204-9bce-8be0348027f3.pf-suggestion-container {
            width:calc(100% + 300px);
            transform:translateX(-100px);
            padding:27px 28px 27px 28px;
            
            &.open {
                &:before {
                    left:130px;
                }
            }

            .pf-col-0 {
                max-height:calc(100vh - 165px);
                overflow-x:hidden;
                overflow-y:auto;
            }

            .pf-col-1 {
                margin-left:28px;
                width:calc(100% - 218px);
                max-height:calc(100vh - 165px);
                overflow-x:hidden;
                overflow-y:auto;
            }
        }
    }   

    .main-header.fixed {
        .search-box-outer {
            left:315px;
            width:calc(100% - 645px);
        }   
    }
}

@include showMobileMenu {
    .search-box-outer, .main-header.fixed .search-box-outer {
        width:calc(100% - 547px);
        left:223px;
    }
}

@include below-lying-tablet {
    .search-box-outer, .main-header.fixed .search-box-outer {
        width:calc(100% - 375px);
        margin:auto;
        right:auto;

        #pf-203c796f-c33e-4204-9bce-8be0348027f3.pf-suggestion-container {
            width:calc(100vw - 60px);
            transform:translateX(-182px);
            max-height:calc(100vh - 155px);

            &.open {
                &:before {
                    left:212px;
                }
            }

            .pf-col-1 {
                margin-left:28px;
                width:calc(100% - 218px);
            }
        }
    }
}

@include mobile {
    .search-box-outer, .main-header.fixed .search-box-outer {
        display:flex;
        position:absolute;
        left:0px;
        right:0px;
        top:50px;
        height:54px;
        width:100%;
        padding:7px 20px 7px 20px;
        background:$gray;

        form {
            background:$white;
            border-radius:2px;
            height:40px;
            width:100%;

            .field {
                height:40px;
                padding:11px 14px 11px 14px;
            }
        }
        
        #pf-203c796f-c33e-4204-9bce-8be0348027f3.pf-suggestion-container {
            width:calc(100% + 20px);
            transform:translateX(-10px);
            padding:15px 10px 20px 10px;


            &.open {
                &:before {
                    left:0px;
                    right:0px;
                    margin:auto;
                }
            }

            .pf-col-1 {
                display:none;
            }

            .pf-col-0 {
                width:100%;
            }

            .pf-group-title {
                font-size:14px;
                padding:0px 5px 0px 5px;
                margin-bottom:8px;
            }

            .pf-suggestion-list {
                li {
                    .pf-suggestion {
                        padding:5px 5px 5px 5px;

                        .pf-suggestion-body {
                            .pf-suggestion-body-text1 {
                                font-size:12px;
                                line-height:16px;
                            }
                        }
                    }
                }
            }
        }
    }
}