@import './all.scss';


body {
  &.opened-mobile-menu {
    overflow:hidden;

    .mobile-menu-overlay {
      opacity:1;
      visibility:visible;
    }

    .mobile-menu {
      transform:translateX(0px);
    }
  }
}

.mobile-menu-overlay {
  width:100vw;
  height:100vh;
  background:$white;
  position:fixed;
  z-index:102;
  left:0px;
  top:0px;
  transition:$opacity-transition;
  transition-delay:40ms;
  visibility:hidden;
  opacity:0;
  background:rgba(49, 59, 79, 0.6);
}

  
.mobile-menu {
  background:$white;
  width:330px;
  max-width:100%;
  height:100%;
  position:fixed;
  top:0px;
  left:0px;
  z-index:103;
  opacity:1;
  transition:$transform-transition;
  transform: translateX(-110%);

  .mobile-menu-inner {
    width:100%;
    height:100%;
    overflow:hidden;

    .mobile-menu-page {
      width:100%;
      height:100%;
      position:absolute;
      top:0px;
      left:0px;
      right:0px;
      bottom:0px;
      z-index:2;
      overflow:hidden;

      &.active {
        z-index:3;
      }

      .sites-content {
        height:calc(100% - 46px);
        overflow-y:auto;
        overflow-x:hidden;
        background:$white;
        
        &:after {
          content:'';
          width:100%;
          height:40px;
          min-height:40px;
          display:block;
        }
      }
    }
  }

  .close-menu-btn {
    position:absolute;
    top:13px;
    right:15px;
    color:$white;
    z-index:10;
    width:22px;
    height:21px;
    padding:5px;
    opacity:0.6;
  }

  a {
    color:inherit;
  }

  .level-header {
    background:$red;
    color:$white;
    height:46px;
    display:flex;
    justify-content:flex-start;
    align-items:center;
    padding:0px 20px 0px 20px;
    position:relative;
    top:0px;
    left:0px;
    right:0px;
    width:100%;

    &:not(.first-level-header) {
      font-size:14px;
      line-height:22px;
      letter-spacing:0.2px;
      font-weight:$bold-fweight;
    }
    
    .mobile-menu-arrow {
      transform:rotate(180deg);
      margin-right:9px;
    }

    .netkazan-logo {
      width:102px;
      height:27px;
    }

    .back-link {
      display:flex;
      justify-content:flex-start;
      align-items:center;
      font-size:0px;
      line-height:0px;
      padding:0px;
      margin:0px;
      text-decoration:none;
      background:transparent;
      border:none;
      height:auto;

      .text {
        font-size:14px;
        line-height:22px;
        letter-spacing:0.2px;
        font-weight:$bold-fweight;
      }
    }
  }

  .mobile-menu-item {
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
    font-size:0px;
    line-height:0px;
    text-decoration:none;

    &:not(.first-level-item) {
      padding:10px 20px 10px 20px;
      border-bottom:1px solid $gray;
    }

    .text {
      font-size:14px;
      line-height:18px;
      letter-spacing:0.2px;
      width:calc(100% - 6px);
      padding-right:20px;
      display:flex;
      justify-content:flex-start;
      align-items:center;

      .icon {
        margin-left:3px;
      }

      .action-icon {
        margin-bottom:1px;
      }

    }

    .arrow-outer {
      width:6px;
      height:11px;
      margin-top:3px;
    }
  }

  .top-content {
    .mobile-menu-item {
      padding:10px 20px 9px 20px;
      border-bottom:1px solid $gray;

      &.first-level-item {
        padding:18px 20px 17px 20px;

        &.product-categories {
          color:$white;
          background:$blue;
          border-bottom:1px solid $blue;
        }
  
        &.application-areas {
          background:$gray;
        }
        
        .text {
          font-weight:$bold-fweight;
          font-size:16px;
        }
      }

      &.second-level-item {
        .text {
          font-weight:$bold-fweight;
        }
      }
    }
  }

  .bottom-content {
    margin-top:18px;

    > div {
      margin-bottom:16px;
      &:last-of-type {
        margin-bottom:0px;
      }
    }

    .mobile-menu-item {
      padding:2px 20px 2px 20px;
      border:none;
    }
  }
}