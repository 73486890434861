@import './all.scss';

body .remodalwindow.storage-info-remodal > .inner-content {
  padding:50px 20px 38px 20px;
}

.storage-info-remodal {
  max-width:360px;
  width:100%;
  color:$primary-color;
  text-align:left;
  font-size:14px;
  line-height:22px;

  a {
    color:inherit;
    text-decoration:none;
  }

  .storage-info {
    display:inline-flex;
    align-items:center;
    font-size:0px;
    line-height:0px;

    .icon {
      margin-right:3px;
    }

    .text {
      font-size:12px;
      line-height:18px;
      font-weight:$bold-fweight;
      letter-spacing:0.2px;
    }

    &.in-stock {
      color:$green;
    }

    &.available {
      color:$secondary-color;
    }

    &.sold-out {
      color:$red;
    }
  }

  .bold-fweight {
    font-weight:$bold-fweight;
  }

  .product-lying-card {
    display:flex;
    align-items:flex-start;

    .image-outer {
      width:85px;
      height:85px;
      position:relative;

      img {
        max-height:85px;
        max-width:100%;
        margin:auto;
      }

      .tags-container {
        position:absolute;
        width:100%;
        height:100%;
        top:0px;
        left:0px;

        .tags-top {
          position:absolute;
          top:0px;
          left:0px;
        }

        .tags-bottom {
          position:absolute;
          left:0px;
          bottom:0px;
          transform:translateY(calc(100% + 7px));
        }
      }
    }

    > .text-content {
      width:calc(100% - 85px);
      padding-left:20px;

      .name-outer {
        line-height:20px;
      }

      .storage-info {
        margin-top:2px;
      }
      
      h3 {
        font-size:14px;
        line-height:20px;
        font-weight:$bold-fweight;
      }
    }

    .price-content {
      display:block;
      margin-top:8px;
      
      .row {
          display:flex;
          align-items:flex-end;

          &.sell-price-row {
              .label {
                  line-height:16px;
              }
          }

          .label {
              font-size:12px;
              line-height:14px;
              letter-spacing: -0.35px;
              margin-right:6px;
          }

          .original-price {
              position:relative;
              font-size:12px;
              line-height:14px;
              display:inline-block;
              
              &:before {
                  content: '';
                  width:100%;
                  height:1px;
                  background:$red;
                  display:block;
                  position:absolute;
                  top:0px;
                  left:0px;
                  transform:rotate(7deg) translateY(7px);
              }
          }

          .price {
              font-size:18px;
              line-height:20px;
              letter-spacing:0.2px;
              font-weight:$bold-fweight;
          }
      }
    }

    .bottom-content {
      display:flex;
      justify-content:space-between;
      align-items:flex-end;

      .price-content {
        width:calc(100% - 44px);
        padding-right:10px;
      }
    }

    .cart-button {
      color:$white;
      width:44px;
      height:44px;
      padding:0px;
      display:flex;
      justify-content:center;
      align-items:center;
    }
  }

  .store-infos {
    margin-top:20px;
    padding-top:20px;
    border-top:1px solid $gray;
  }

  .store-box {
    display:flex;
    align-items:flex-start;
    margin-bottom:30px;

    &:last-of-type {
      margin-bottom:0px;
    }

    .left-content {
      width:110px;

      .title {
        font-weight:$bold-fweight;
        font-size:16px;
      }
    }

    .text-content {
      width:calc(100% - 110px);
      padding-left:25px;

      .address {
        margin-bottom:10px;
      }
    }
  }
}

@media screen and (max-width:359px) {
  .storage-info-remodal {
    .product-lying-card {
      .cart-button {
        width:34px;
        height:34px;

        .cart-icon {
          width:18px;
          height:15px;
        }
      }

      .bottom-content {
        .price-content {
          width:calc(100% - 34px);

          .row {
            &.sell-price-row {
              .price {
                font-size:14px;
                line-height:18px;
              }
            }
          }
        }
      }
    }
  }
}