@import 'all.scss';

.product-card {
    position:relative;
    width:200px;
    z-index:1;

    > .arrow-link {
        margin-top:15px;
        display:inline-block;
        z-index:2;
    }

    .inner {
        font-size:0px;
        line-height:0px;
        text-decoration: none;
        color:$primary-color;
        background:$white;

        .image-outer {
            display:flex;
            width:100%;
            height:180px;
            position:relative;
            justify-content:center;
            align-items:center;

            &.small {
                height:120px;
            }

            .tags-container {
                position:absolute;
                top:0px;
                left:0px;

                > span {
                    display:block;
                }

                .product-tag, .warranty-tag {
                    margin-bottom:5px;
                }

                .product-tag {
                    margin-right:5px;
                }
            }

            img {
                max-height:100%;
                margin:auto;
            }
        }

        .text-content {
            margin-top:8px;
            width:100%;
            display:block;

            .name-outer {
                height:60px;
                text-decoration:none;
                color:$primary-color;
                overflow:hidden;
                display:block;

                h3, .category-name {
                    display:inline;
                    font-size:14px;
                    line-height:20px;
                }

                h3 {
                    margin:0px;
                }

                .title {
                    font-weight:$bold-fweight;
                }

                a {
                    text-decoration:none;
                    color:$primary-color;
                }
            }

        }
        .storage-row {
            display:flex;
            align-items:center;
            margin-top:7px;
            margin-bottom:7px;
            
            .storage-info {
                margin-right:14px;
            }

            .storage-info-btn {
                font-size:12px;
                line-height:16px;
                text-decoration:underline;
                cursor:pointer;
            }
        }
        .storage-info {
            display:flex;
            align-items:center;
            height:16px;
            color:$green;

            &.in-stock {
                color:$green;
            }

            &.available {
                color:$secondary-color;
            }

            &.to-order {
                color:$orange;
            }

            &.sold-out {
                color:$red;
            }
            

            .icon {
                margin-right:3px;
            }

            .text {
                font-size:12px;
                line-height:16px;
                font-weight:$bold-fweight;
            } 
        }

        .bottom-content {
            display:flex;
            justify-content:space-between;
            align-items: flex-end;
            height:44px;
        }


        .price-content {
            display:block;

            .row {
                display:block;
                font-size:12px;
                line-height:16px;

                .original-price {
                    position:relative;
                    display:inline-block;

                    &:before {
                        content: '';
                        width:100%;
                        height:1px;
                        background:$red;
                        display:block;
                        position:absolute;
                        top:0px;
                        left:0px;
                        transform:rotate(7deg) translateY(7px);

                    }
                }

                &.sell-price-row {
                    display:inline-flex;
                    align-items:flex-end;
                    letter-spacing:0.2px;

                    .price {
                        margin-left:4px;
                        font-size:18px;
                        line-height:20px;
                        font-weight:$bold-fweight;
                    }
                }
            }
        }

        .hover-content {
            display:none;
        }

        .rating-content {
            display:flex;
            align-items:center;
            
            .stars {
                display:flex;
                margin-right:6px;
                
                .icon {
                    margin-right:2px;
                    color:$red;

                    &.gray {
                        color:$dark-gray;
                    }

                    &:last-of-type {
                        margin-right:0px;
                    }
                }

                &.star-0 {
                    .icon {
                        color:$dark-gray;
                    }
                }

                &.star-4 {
                    .icon {
                        &:nth-of-type(5){
                            color:$dark-gray;
                        }
                    }
                }

                &.star-3 {
                    .icon {
                        &:nth-of-type(5), &:nth-of-type(4){
                            color:$dark-gray;
                        }
                    }
                }

                &.star-2 {
                    .icon {
                        &:nth-of-type(5), &:nth-of-type(4), &:nth-of-type(3) {
                            color:$dark-gray;
                        }
                    }
                }

                &.star-1 {
                    .icon {
                        color:$dark-gray;
                        &:first-of-type {
                            color:$red;
                        }
                    }
                }
            }

            .text {
                font-size:12px;
                line-height:17px;
            }
        }

        .parameters {
            display:block;
            .row {
                display:block;
                font-size:12px;
                line-height:16px;

                .value {
                    font-weight:$bold-fweight;
                }

                sup {
                    font-size:8px;
                }

                a {
                    color:$primary-color;
                    text-decoration:none;
                }
            }
        }

        .description {
            display:block;
            font-size:12px;
            line-height:18px;
        }
    }

    &:not(.lying-card) {
        .manufacturer-image {
            display:none;
        }
        
        .bottom-content {
            .price-content {
                width:calc(100% - 50px);
            }
        }
        .button {
            padding:0px;
            width:44px;
            height:44px;
            
            .text {
                display:none;    
            }
        }

        .hover-content {
            margin-top:16px;
            padding-top:21px;
            border-top:1px solid $gray;
        }

        .parameters {
            margin-top:13px;
        }

        .description {
            margin-top:10px;
        }

        .details-button-row {
            display:none;
            justify-content:flex-start;
            align-items:center;
            color:$red;
            
            .text {
                font-size:12px;
                line-height:16px;
                font-weight:$bold-fweight;
                letter-spacing: 0.2px;
                margin-right:7px;
            }
            
            .icon {
                width:9px;
                height:6px;
                transition:$transform-transition;
            }

            &.opened {
                .icon {
                    transform:rotate(180deg);
                }
            }
        }
    }

    &.lying-card {
      .manufacturer-image {
        img {
          height:32px;
        }
      }
        .inner {
            display:flex;
            position:relative;
            width:calc(100% + 60px);
            left:-30px;
            top:0px;
            padding:30px;

            .image-outer {
                width:180px;
            }

            .details-button-row {
                display:none;
            }

            .text-content {
                width:260px;
                padding:0px 38px 0px 22px;
                margin:0px;

                .bottom-content {
                    position:absolute;
                    right:30px;
                    bottom:30px;

                    .button {
                        margin-left:23px;
                    }
                }

                .storage-row {
                    position:absolute;
                    bottom:55px;
                    left:470px;
                    margin:0px;
                }
            }

            .hover-content {
                width:calc(100% - 440px);
                display:flex;
                flex-direction:column;
                padding-top:0px;
                margin-top:0px;
                border-top:none;
                padding-bottom:74px;

                .rating-content {
                    position:absolute;
                    bottom:30px;
                    left:470px;
                }

                .description {
                    height:54px;
                    overflow:hidden;
                    order:1;
                }

                .parameters {
                    margin-top:8px;
                    column-count:2;
                    column-gap:20px;
                    order:2;

                    > .row {
                        margin-bottom:6px;
                        display:inline-block;
                        width:100%;
                    }

                    .special-parameter {
                        position:absolute;
                        left:232px;
                        bottom:66px;
                        width:200px;

                        .row {
                            margin-top:6px;
                        }
                    }
                }
            }
        }
    }

    &.with-arrow-link {
        position:relative;
        padding-top:261px;
        .inner {
            top:0px;
            left:0px;
            position:absolute;
        }
    }
}

@include hoverStatements {
    @media screen and (min-width:1025px) {
        .product-card {
            &:hover {
                z-index:2;
            }

            &:not(.lying-card) {
                &.with-arrow-link, &.upsell-product-card {
                    .inner {
                        &:hover {
                            z-index:3;
                            $hoverPadding: 15px;
                            padding:$hoverPadding;
                            left:#{$hoverPadding * -1};
                            top:#{$hoverPadding * -1};
                            width:calc(100% + #{$hoverPadding * 2});
                        }
                    }
                }
            }

            .inner {
                .storage-row {
                    .storage-info-btn {
                        &:hover {
                            text-decoration:none;
                        }
                    }
                }

                &:hover {
                    z-index:3;
                    padding:30px;
                    left:-30px;
                    width:calc(100% + 60px);
                    box-shadow: 0 0 21px 6px rgba(0,0,0,0.08);
                    
                }
            }

            &:not(.lying-card) {
                .inner {
                    &:hover {
                        position:absolute;
                        top:-30px;

                        &:not(.without-parameter) {
                            .hover-content {
                                display:block;
                            }
                        }
                    }
                }
            }

            &.lying-card {
                .inner {
                    &:hover {
                        position:relative;
                        top:0px;
                    }
                }
            }
        }
    }

    @media screen and (min-width:1025px) and (max-width:1300px) {
        .product-card {
            &:not(.lying-card) {
                .inner {
                    &:hover {
                        padding:20px;
                        left:-20px;
                        top:-20px;
                        width:calc(100% + 40px);
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1279px) {
    .product-card {
        &.lying-card {
            .inner {
                .text-content {
                    padding:0px 20px 0px 20px;
                    width:220px;

                    .bottom-content {
                        .button {
                            margin-left:15px;
                            width:44px;
                            height:44px;
                            padding:0px;
                            font-size:0px;
                            line-height:0px;
                            padding-right:1px;
                            
                            .text {
                                display:none;
                            }
                        }
                    }

                    .storage-row {
                        left:430px;
                    }
                }

                .hover-content {
                    width:calc(100% - 400px);

                    .rating-content {
                        left:430px;
                    }

                    .parameters {
                        .special-parameter {
                            width:180px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1120px) {
    .product-card {
        &.lying-card {
            .inner {
                .text-content {
                    .storage-row {
                        display:flex;
                        flex-direction:column;
                        align-items:flex-start;
                        
                        .storage-info {
                            margin:0px;
                        }

                        .storage-info-btn {
                            margin-top:5px;
                        }
                    }
                }
            }
        }
    }
}

@include mobile {
    .product-card {
        width:100%;
        
        &.with-arrow-link {
            padding-top:0px;
            border-bottom:1px solid $gray;
            padding-bottom:15px;
            display:flex;
            justify-content:flex-end;
            flex-wrap:wrap;

            .inner {
                &, &.without-parameters {
                    position: relative;
                    padding-bottom:0px;
                    border-bottom:0px;
                }
            }

            > .arrow-link {
                margin-right:20px;
                width:calc(100% - 145px);
            }
        }

        .inner {
            display:flex;
            align-items:flex-start;
            flex-wrap:wrap;
            border-bottom:1px solid $gray;
            padding:15px 0px 0px 0px;
            width:100%;

            &.without-parameters {
                padding-bottom:15px;

                .text-content {
                    padding-bottom:0px;
                }
            }

            .image-outer {
                margin-left:20px;
                width:85px;
                height:85px;
                min-width:85px;
                position:relative;

                &.small {
                    height:auto;
                }

                .tags-container {
                    height:100%;
                    width:100%;
                    
                    .tags-bottom {
                        position:absolute;
                        left:0px;
                        bottom:0px;
                        width:100%;
                        transform: translateY(calc(100% + 7px));
                    }
                }
            }

            .text-content {
                width:calc(100% - 105px);
                padding:0px 20px 8px 20px;
            }

            .details-button-row {
                margin-top:9px;
                display:flex;
            }

            .hover-content {
                display:none;
                background:$light-gray;
                padding:15px 20px 15px 20px;
                border:none;
                margin:0px;

                .parameters {
                    display:block;
                    column-count: 2;
                    column-gap:20px;
                    letter-spacing: -0.1px;

                    .row {
                        display:inline-block;
                        margin-bottom:6px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:359px) {
    .product-card {
        .inner {
            .bottom-content {
                .price-content {
                    width:calc(100% - 40px);

                    .row {
                        &.sell-price-row {
                            .price {
                                font-size:16px;
                                line-height:18px;
                            }
                        }
                    }
                }

                .button {
                    width:34px;
                    height:34px;

                    .cart-icon {
                        width:18px;
                        height:15px;
                    }
                }
            }
        }
    }
}