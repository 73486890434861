.icon, .image-icon {
    display:block;
    font-size:0px;
    line-height:0px;
    letter-spacing:0px;
}

.image-icon {
    img {
        width:100%;
        display:block;
    }
}

/* 
    SVG elemek
*/
.netkazan-logo {
  width:152px;
  height:40px;
}

.mobile-menu-icon {
    width:18px;
    height:16px;
}

.profil-icon {
    width:20px;
    height:20px;
}

.footer-profil-icon {
  width:18px;
  height:18px;
}

.logged-in-profil-icon {
    width:23px;
    height:20px;
}

.cart-icon {
    width:20px;
    height:17px;
}

.phone-icon {
    width:15px;
    height:17px;
}

.arrow-down {
    width:12px;
    height:7px;
}

.search-icon {
    width:19px;
    height:19px;
}

.action-icon {
    width:15px;
    height:15px;
}

.evhonlapja-icon {
    width:19px;
    height:31px;
}

.orszag-boltja-icon {
    width:45px;
    height:27px;
}

.check-icon {
  width:16px;
  height:16px;
}

.shipping-tag-icon {
  width:25px;
  height:15px;
}

.arrow-right {
  width:5px;
  height:8px;
}

.fast-shipping-icon {
  width:24px;
  height:17px;
}

.quality-team-mate-icon {
  width:19px;
  height:16px;
}

.store-icon {
  width:17px;
  height:15px;
}

.competitive-prices-icon {
  width:17px;
  height:17px;
}

.coupon-discount-icon {
  width:19px;
  height:19px;
}

.personal-receipt-icon {
  width:23px;
  height:19px;
}

.original-products-icon {
  width:18px;
  height:14px;
}

.payment-transfer-icon {
  width:64px;
  height:37px;
}

.payment-card-icon {
  width:61px;
  height:56px;
}

.takeover-bp-icon,
.takeover-nyiregyhaza-icon {
  width:56px;
  height:49px;
}

.facebook-icon {
  width:34px;
  height:34px;
}

.google-company-icon {
  width:38px;
  height:33px;
}

.google-icon {
  width:32px;
  height:33px;
}

.arukereso-star-icon {
  width:11px;
  height:11px;

  &.big {
    width:14px;
    height:14px;
  }
}


.close-icon {
  width:12px;
  height:11px;
}

.mobile-menu-arrow {
  width:6px;
  height:11px;
}

.horizontal-view-icon {
  width:17px;
  height:15px;
}

.grid-view-icon {
  width:17px;
  height:17px;
}

.rating-star-icon {
  width:16px;
  height:17px;
}

.star-icon {
  width:10px;
  height:10px;
}

.category-arrow-icon {
  width:9px;
  height:8px;
}

.pager-arrow {
  width:7px;
  height:13px;
}

.pager-double-arrow {
  width:13px;
  height:13px;
}

.tooltip-icon {
  width:16px;
  height:16px;
}

.success-list-icon {
  width:16px;
  height:16px;
}

.remodal-close-icon {
  width:18px;
  height:17px;
}

.plus-icon {
  width:9px;
  height:9px;
}

.modify-icon {
  width:20px;
  height:20px;
}

.logout-icon {
  width:14px;
  height:12px;
}

.discount-icon {
  width:17px;
  height:17px;
}

.exchange-icon {
  width:35px;
  height:34px;
}

.exchange-arrow-icon {
  width:26px;
  height:12px;
}

.youtube-video-icon {
  width:30px;
  height:20px;
}

.not-available-icon {
  width:16px;
  height:16px;
}

/*
    Képek
*/
.arukereso-logo {
  width:90px;
  height:15px;
}

.shopmania-logo {
  width:84px;
  height:16px;
}

.technoform-logo {
  width:84px;
  height:37px;
}

.best-machinery-logo {
  width:49px;
  height:25px;
}

.argep-logo {
  width:52px;
  height:26px;
}


.arukereso-round-logo {
  width:93px;
  height:93px;
}

.mail-icon {
  width:20px;
  height:15px;
}