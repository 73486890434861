@import 'all.scss';
@import 'product-card.scss';

.product-slider-section {
    z-index:1;

    h2 {
        margin-bottom:33px;
    }

    h3 {
        margin-bottom:30px;
    }

    .product-slider-outer {
        width:calc(100% - 60px);
        max-width:1240px;
        margin:auto;
        position:relative;

        .pager-outer {
            position:absolute;
            top:0px;
            right:0px;
            width:calc((100vw - 1240px) / 2);
            height:100%;
            background-image: linear-gradient(90deg, rgba(255,255,255,0) 10%, #ffffff 100%);
            z-index:3;
            font-size:0px;
            line-height:0px;
            transform:translateX(100%);
            display:none;

            &.active {
                display:block;
            }

            .pager {
                width:44px;
                height:44px;
                display:flex;
                justify-content:center;
                align-items:center;
                border-radius:$border-radius;
                background:$red;
                color:$white;
                position:absolute;
                top:0px;
                left:10px;
                bottom:0px;
                margin:auto;

                .icon {
                    width:7px;
                    height:12px;
                }
            }

            &.prev {
                transform:translateX(-100%);
                background-image: linear-gradient(-90deg, rgba(255,255,255,0) 10%, #ffffff 100%);
                right:auto;
                left:auto;

                .pager {
                    left:auto;
                    right:10px;
                    .icon {
                        transform:rotate(180deg);
                    }
                }
            }
        }

        .slider-inner {
            display:flex;
            align-items:flex-start;
            transition:$transform-transition;
            min-height:262px;

            .product-card {
                min-width:200px;
                margin-right:55px;

                &:last-of-type {
                    margin-right:0px;
                }
            } 
            
        }
    }
}

@include hoverStatements {
    .product-slider-section {
        .slider-inner {
            position:relative;
            &:hover {
                z-index:2;
            }
        }
    }
}

@media screen and (max-width:1380px) {
    .product-slider-section {
        .product-slider-outer {
            .pager-outer {
                .pager {
                    width:40px;
                    height:40px;
                    left:5px;
                }

                &.prev {
                    .pager {
                        right:5px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1340px) {
    .product-slider-section {
        .product-slider-outer {
            width:calc(100% - 100px);

            .pager-outer {
                width:50px;
            }
        }
    }
}

@include lying-tablet-breakpoint {
    .product-slider-section {
        .product-slider-outer {
            width:100%;
            padding-left:30px;
            overflow-x:auto;
            overflow-y:visible;
            
            .pager-outer {
                &.active {
                    display:none;
                }
            }

            .slider-inner {
                .product-card {
                    &:last-of-type {
                        margin-right:30px;
                    }
                }
            }
        }
    }
}

@include mobile {
    .product-slider-section {
        h2 {
            margin-bottom:10px;
        }

        .product-slider-outer {
            padding-left:20px;

            .slider-inner {
                margin-top:15px;
                padding-bottom:25px;
                min-height:auto;

                .product-card {
                    width:200px;
                    min-width:200px;
                    max-width:200px;
                    margin-right:40px;

                    &.with-arrow-link {
                        border-bottom:none;
                        padding-bottom:0px;

                        > .arrow-link {
                            width:100%;
                            margin:10px 0px 0px 0px;
                        }
                    }

                    &:last-of-type {
                        margin-right:20px;
                    }
                    
                    .inner {
                        display:block;
                        padding-top:0px;
                        border-bottom:none;

                        .image-outer {
                            width:100%;
                            height:120px;
                            margin:0px 0px 0px 0px;

                            .tags-container {
                                .tags-bottom {
                                    transform:none;
                                }
                            }
                        }

                        .text-content {
                            width:100%;
                            padding:0px;
                        }

                        .details-button-row {
                            display:none;
                        }
                    }
                }
            }
        }
    }
}