@import 'all.scss';
@import 'search-box.scss';
@import 'megamenu.scss';

.main-header {
    letter-spacing: 0.2px;
    padding-top:111px;

    &.small-header {
        padding-top:55px;
    }

    a {
        color:inherit;
        text-decoration:none;
        font-weight:$bold-fweight;
    }

    > .top {
        position:absolute;
        background:$red;
        color:$white;
        z-index:14;
        top:0px;
        left:0px;
        right:0px;
        width:100%;
        height:55px;

        .mobile-menu-btn, .megamenu-icon-outer {
            display:none;
        }

        .logo-content {
            display:block;
            width:137px;
            height:36px;
            font-size:0px;
            line-height:0px;
            transform:translateY(-1px);

            .icon {
                width:100%;
                height:100%;
            }
        }

        .content-width {
            display:flex;
            justify-content:space-between;
            align-items:center;
        }

        .right-content {
            ul {
                list-style:none;
                margin:0px;
                padding:0px;
                display:flex;
                justify-content:flex-end;
                align-items:center;

                > li {
                    margin-right:25px;
                    height:55px;
                    display:flex;
                    align-items:center;

                    &:last-of-type {
                        margin-right:0px;
                    }

                    > a {
                        font-size:0px;
                        line-height:0px;
                        text-decoration:none;
                        display:inline-flex;
                        align-items:center;
                        justify-content:flex-start;

                        .text {
                            font-size:$primary-fsize;
                            line-height:20px;
                            font-weight:$bold-fweight;
                            margin-left:8px;
                        }

                        &.phone-link {
                            .text {
                                margin-left:2px;
                            }
                        }

                        &.cart-item {
                            .arrow-down {
                                margin-left:5px;
                                transition:$transform-transition;
                            }

                            .icon-outer {
                                position:relative;
                                
                                .feedback-content {
                                    width:8px;
                                    height:8px;
                                    display:block;
                                    border-radius:50%;
                                    background:$blue;
                                    position:absolute;
                                    top:-3px;
                                    right:-4px;
                                    opacity:1;
                                    transition:$opacity-transition;
                                }
                            }
                        }
                    }

                    &.has-submenu {
                        position:relative;

                        .submenu {
                            display:none;
                            position:absolute;
                            top:100%;
                            left:50%;
                            transform:translateX(-50%);
                            box-shadow: 0 11px 14px 1px rgba(49,59,79,0.15);
                            visibility:hidden;
                            opacity:0;
                            transition:$opacity-transition;
                            transition-delay:200ms;

                            &:after {
                                width:0px;
                                height:0px;
                                content:'';
                                border-style:solid;
                                border-width:0px 12px 11px 12px;
                                border-color: transparent transparent $white transparent;
                                display:block;
                                position:absolute;
                                right:0px;
                                left:0px;
                                bottom:100%;
                                margin:auto;
                            }
                        }
                    }

                    &.cart-list-item {
                        position:relative;


                        &.empty-cart {
                            > a {
                                .arrow-down {
                                    display:none;
                                }   

                                .icon-outer {
                                    .feedback-content {
                                        opacity: 0;
                                    }
                                }
                            }
                        }

                        .cart-content {
                            display:none;
                            position:absolute;
                            top:100%;
                            right:0px;
                            left:auto;
                            transform:none;
                            background:$white;
                            z-index:2;
                            width:378px;
                            color:$primary-color;

                            &:after {
                                left:auto;
                                right:38px;
                                margin:0px;
                            }
                            
                            > .inner {
                                padding:22px 32px 28px 32px;
                            }

                            .title {
                                font-size:18px;
                                line-height:26px;
                                font-weight:$bold-fweight;
                                margin-bottom:6px;
                            }

                            .items {
                                max-height:250px;
                                overflow-y:auto;
                                overflow-x:hidden;
                            }

                            .row {
                                display:flex;
                                justify-content:space-between;
                                align-items: flex-start;
                                padding:8px 0px 8px 0px;
                                border-bottom:1px solid $gray;

                                &:last-of-type {
                                    border-bottom:none;
                                }

                                .name {
                                    color:$red;
                                    font-size:12px;
                                    line-height:18px;
                                    letter-spacing:0.2px;
                                    width:calc(100% - 90px);

                                    a {
                                        font-weight:400;
                                    }
                                }

                                .price {
                                    font-weight:$bold-fweight;
                                    font-size:14px;
                                    line-height:18px;
                                    width:90px;
                                    text-align:right;
                                }
                            }

                            .summary-content {
                                padding-top:13px;
                                border-top:1px solid $gray;

                                .row {
                                    justify-content:flex-end;
                                    padding:0px;
                                    border:none;
                                    align-items:flex-end;
                                    margin-bottom:6px;
                                    
                                    &:last-of-type {
                                        margin-bottom:0px;
                                    }

                                    .label {
                                        font-size:12px;
                                        line-height:16px;
                                    }
                                }
                            }

                            .buttons {
                                display:flex;
                                justify-content:flex-end;
                                align-items:center;
                                margin-top:22px;

                                .button {
                                    color:$white;
                                    height:32px;
                                    padding:0px 15px 0px 16px;

                                    .text {
                                        font-size:12px;
                                    }
                                }

                                a {
                                    margin-right:16px;

                                    &:last-of-type {
                                        margin-right:0px;
                                    }

                                    &:not(.button) {
                                        color:$blue;
                                        text-transform: uppercase;
                                        font-size:10px;
                                        line-height:14px;
                                        letter-spacing:1px;
                                        font-weight:$bold-fweight;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    > .bottom {
        background:$gray;
        z-index:13;
        position:absolute;
        height:56px;
        width:100%;
        top:55px;
        left:0px;
        right:0px;

        .content-width {
            display:flex;
            justify-content:space-between;
            align-items:center;
        }

        .opening-button {
            font-size:12px;
            line-height:20px;
            font-weight:400;
            padding:1px 8px 1px 8px;
            border-radius:$border-radius;
            background-color:$white;
            display:block;
            transition: $bg-color-transition, $color-transition;
        }

        .main-menu-list {
            list-style:none;
            margin:0px;
            padding:0px;
            display:flex;
            justify-content:flex-start;
            align-items:center;
            height:56px;

            > li {
                &.main-menu-item {
                    margin-right:30px;
                    height:100%;
                    
                    > a {
                        display:flex;
                        justify-content:center;
                        align-items:center;
                        height:100%;
                        font-size:0px;
                        line-height:0px;

                        .text {
                            font-size:14px;
                            line-height:17px;
                        }
                        
                        .icon-outer {
                            margin-left:3px;
                        }
                    }

                    &.has-submenu {
                        position:relative;
                        > a {
                            .icon-outer {
                                margin-left:6px;
                                transition:$transform-transition;
                            }
                        }
                    }

                    .submenu {
                        position:absolute;
                        display:none;
                        top:100%;
                        left:50%;
                        width:192px;
                        transform:translateX(-50%);
                        padding:20px 32px 28px 32px;
                        background:$white;
                        box-shadow: 0 11px 14px 1px rgba(49,59,79,0.15);

                        &:after {
                            content:'';
                            width:0;
                            height:0px;
                            border-style:solid;
                            border-width:0px 12px 11px 12px;
                            border-color:transparent transparent $white transparent;
                            bottom:100%;
                            left:0px;
                            right:0px;
                            position:absolute;
                            margin:auto;
                        }

                        .row {
                            text-align:left;
                            margin-bottom:12px;
                            &:last-of-type {
                                margin-bottom:0px;
                            }

                            a {
                                font-weight:400;
                                font-size:14px;
                                line-height:22px;
                            }
                        }
                    }
                }

                &.megamenu-item {
                    background:$blue;
                    color:$white;
                    margin-right:38px;
                    
                    a {
                        padding:0px 20px 0px 19px;

                        .text {
                            margin-left:10px;
                        }

                        &.icon-outer {
                            margin-left:0px;
                        }
                    }
                }
            }
        }
    }

    &.fixed {
        > .top {
            top:-60px;
            position:fixed;
            @include transform(translateY(60px));
            transition:$transform-transition;
            transition-delay:100ms;

            .logo-content-outer {
                display:flex;
                align-items:center;
                
                .megamenu-icon-outer {
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    width:60px;
                    height:55px;
                    background:$blue;
                    margin-left:23px;

                    .close-icon {
                        display:none;
                    }
                }

                &.hovered {
                    .megamenu-icon-outer {
                        .close-icon {
                            display:block;
                        }

                        .mobile-menu-icon {
                            display:none;
                        }
                    }
                }
            }
        }
    }

    .profil-submenu {
        background:$white;
        width:180px;
        color:$primary-color;
        padding:20px 32px 28px 32px;

        .row {
            margin-bottom:12px;

            &:last-of-type {
                margin-bottom:0px;
            }
        }
    }
}



@include hoverStatements {
    .main-header {
        > .top {
            &:hover {
                z-index:14;
            }

            .right-content {
                > ul {
                    > li {
                        &.has-submenu:hover {
                            &:not(.empty-cart) {
                                > a {
                                    .arrow-down {
                                        transform:rotate(180deg);
                                    }
                                }

                                .submenu {
                                    display:block;
                                    opacity:1;
                                    visibility:visible;
                                }
                            }
                        }
                    }
                }
            }
        }

        > .bottom {
            &:hover {
                z-index:13;
            }
            a {
                &:hover {
                    color:$red;

                    &.megamenu-button, &.opening-button {
                        color:$white;
                    }
                }
            }

            .main-menu-item {
                &.has-submenu {
                    &:hover {
                        .icon-outer {
                            transform:rotate(180deg);
                        }

                        .submenu {
                            display:block;
                        }
                    }
                }
            }

            .opening-button {
                &:hover {
                    color:$white;
                    background-color:$red;
                }
            }

            
        }

        .profil-submenu {
            a {
                &:hover {
                    color:$red;
                }
            }
        }
    }
}

@include showMobileMenu {
    .main-header {
        padding-top:55px;

        > .bottom {
            display:none;
        }

        &.fixed > .top, > .top {
            .content-width {
                padding-left:25px;
            }
            .logo-content-outer {
                display:flex;
                justify-content:flex-start;
                align-items:center;

                .megamenu-icon-outer {
                    display:none;
                }
            }

            .logo-content {
                height:35px;
                width:133px;
            }

            .mobile-menu-btn {
                width:28px;
                display:flex;
                height:100%;
                padding:0px;
                margin-right:15px;
                font-size:0px;
                line-height:0px;

                padding:5px;
                .mobile-menu-icon {
                    width:20px;
                    height:18px;
                }
            }

            .right-content {
                ul {
                    > li {
                        > a.cart-item {
                            .arrow-down {
                                display:none;
                            }
                        }

                        &.has-submenu {
                            &, &:hover {
                                &:not(.empty-cart) {
                                    .submenu {
                                        display:none;
                                        opacity:0;
                                        visibility:hidden;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .profil-icon {
                width:22px;
                height:22px;
            }

            .phone-icon {
                width:17px;
                height:19px;
            }

            .cart-icon {
                width:22px;
                height:19px;
            }
        }
    }
}

@include below-lying-tablet {
    .main-header {
        > .top {
            .right-content {
                ul {
                    > li {
                        margin-right:20px;
                        > a {
                            .text {
                                display:none;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include mobile {
    .main-header {
        padding-top:104px;

        &.small-header {
            padding-top:50px;

            > .top {
                height:50px;
            }
        }

        > .top {
            height:104px;

            .content-width {
                padding-left:15px;
            }

            .mobile-menu-btn {
                margin-right:15px;
            }

            .logo-content {
                width:102px;
                height:27px;
                transform:translateY(0px);

                .netkazan-logo, .new-netkazan-logo {
                    width:100%;
                    height:100%;
                }
            }

            .right-content {
                > ul {
                    > li {
                        height:50px;
                    }
                }
            }
        }

        &.fixed {
            > .top {
                top:-110px;
                @include transform(translateY(110px));

                .content-width {
                    padding-left:15px;
                }

                .logo-content {
                    width:102px;
                    height:27px;
                    transform:translateY(0px);
    
                    .netkazan-logo, .new-netkazan-logo {
                        width:100%;
                        height:100%;
                    }
                }
            }
        }
    }
}
