@import 'all.scss';

.megamenu-container {
    position:absolute;
    top:111px;
    left:0px;
    right:0px;
    z-index:10;    

    &.always-open, &.visible {
        .megamenu {
            display:block;
        }
    }

    &:after {
        content:'';
        background:$primary-color;
        opacity:0;
        visibility:hidden;
        position:fixed;
        width:100%;
        top:0px;
        left:0px;
        right:0px;
        bottom:0px;
        display:block;
        z-index:1;
        transition:$opacity-transition;
        transition-delay: 50ms;
    }

    &.opened, &.visible {
        &:after {
            opacity:0.6;
            visibility:visible;
        }

        .megamenu {
            .megamenu-details {
                display:flex;
            }

            .megamenu-list-container {
                box-shadow: none;
                border-right: 1px solid $gray;
            }
        }
    }

    .megamenu {
        display:none;
        width:calc(100% - 60px);
        max-width:1240px;
        margin:auto;
        position:relative;
        height:calc(100vh - 140px);
        max-height:434px;

        .menu-item {
            display:flex;
            justify-content:space-between;
            align-items:flex-start;

            &.active-item {
                color:$red;
            }
        }

        .arrow-right {
            min-width:5px;
            min-height:8px;
            transform:translateY(7px);
        }

        .megamenu-list-container {
            height:100%;
            overflow:auto;
            width:285px;
            background:$white;
            box-shadow: 0 4px 14px 1px rgba(0,0,0,0.10);
            border-right:1px solid $white;
            position:relative;
            z-index:4;
            
            transition:box-shadow 0.25s ease;

            .megamenu-list-outer {
                overflow-x:hidden;
                overflow-y:auto;
                padding:27px 32px 20px 30px;
                width:100%;
                height:100%;
            }

            &:after {
                content:'';
                height:45px;
                position:absolute;
                left:0px;
                right:8px;
                bottom:0px;
                background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
            }

            ul {
                margin:0px;
                padding:0px;
                list-style:none;

                li {
                    margin-bottom:15px;
                    position:relative;
                    
                    &:last-of-type {
                        margin-bottom:20px;
                        z-index:10;
                    }
                }
            }
        }

        .megamenu-details {
            z-index:3;
            width:calc(100% - 285px);
            height:100%;
            overflow: hidden;
            display:none;
            background:$white;
            position:absolute;
            top:0px;
            left:285px;

            .second-level-outer, .third-level-outer {
                height:100%;
                padding:0px 0px 0px 30px;
                position:relative;
                opacity:0;
                width:calc((100% - 290px) / 2);
                border-right:1px solid $gray;


                &.active {
                    opacity:1;
                }

                .inner-box {
                    height:100%;
                    display:block;
                    padding-bottom:0px;
                                        
                    .inner-content {
                        padding-top:27px;
                        overflow-y:auto;
                        overflow-x:hidden;
                        padding-right:30px;
                        height:100%;
                    }

                    &:after {
                        content:'';
                        height:45px;
                        position:absolute;
                        left:0px;
                        right:8px;
                        bottom:0px;
                        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
                    }
                }


                a {
                    font-weight:400;
                }

                .inner-box {
                    display:none;

                    &.active {
                        display:block;
                    }
                }

                ul {
                    margin:0px;
                    padding:0px;
                    list-style:none;

                    li {
                        font-size:14px;
                        line-height:18px;
                        margin-bottom:15px;
                        font-weight:400;
                        position:relative;
                        &:last-of-type {
                            margin-bottom:20px;
                            z-index:2;
                        }
                    }
                }
            }

            .fix-content {
                padding:27px 40px 40px 20px;
                width:290px;
                display:flex;
                justify-content:flex-end;
                
                .inner {
                    max-width:230px;
                    display:flex;
                    align-items:flex-end;
                    justify-content:space-between;
                    flex-direction: column;
                }

                .top-content {
                    width:100%;
                }

                a {
                    color:$red;
                }

                .product-card {
                    .name-outer {
                        font-size:0px;
                        line-height:0px;
                        font-weight:400;
                        
                        .title {
                            font-size:14px;
                            line-height:20px;
                            font-weight:400;

                            .name {
                                font-weight:$bold-fweight;
                            }
                        }
                    }
                }

                ul {
                    margin:0px;
                    padding:0px;
                    list-style:none;

                    li {
                        margin-bottom:15px;

                        &:last-of-type {
                            margin-bottom:0px;
                        }
                    }
                }
            }
        }
    }
}

.main-header.fixed {
    .megamenu-container {
        &.visible, &.always-open.visible {
            position:fixed;
            top:55px;
    
            .megamenu {
                display:block;
            }
        }
    }
}

@include hoverStatements {
    .megamenu-container {
        .megamenu {
            .megamenu-list-container {
                a {
                    &:hover {
                        color:$red;
                    }
                }
            }
            .megamenu-details {
                .second-level-outer, .third-level-outer {
                    a {
                        &:hover {
                            color:$red;
                        }
                    }
                }

                .fix-content {
                    a {
                        &:hover {
                            color:$primary-color;
                        }
                    }

                    .product-card {
                        .inner {
                            &:hover {
                                position:relative;
                                top:0px;
                                box-shadow:none;
                                width:100%;
                                padding:0px;
                                left:0px;

                                .name-outer {
                                    color:$red;
                                }
                            }
                        }
                    }
                }
            }
        }

        &:hover {
            z-index:12;
        }
    }
}

@media not all and (pointer:coarse) {
    .megamenu-container {
        /* width */
        ::-webkit-scrollbar {
            width: 8px;
            height: 4px;
            border-radius:2px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #e3e7ea;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: #969696;
            transition:background 0.25s ease;
        }
    }
}

@include showMobileMenu {
    .megamenu-container {
        display:none;
    }   
}